import { useForm } from "react-hook-form";
import "./index.css";
import close from "../../assets/icons/cross-button-icon.svg";
import Modal, { ModalProps } from "../../library/components/Modal";
import Button, { ButtonVariants } from "../../library/components/Button";
import TextField from "../../library/components/TextField";
import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import ResponseStatus, {
  ResponseStatuses,
} from "../../library/components/ResponseStatus";
import { useAccountApi } from "../../hooks/useAccountApi";

interface ForgotPasswordForm {
  email: string;
}

const ForgotPasswordModal = ({ isOpen, toggle }: ModalProps) => {
  const { forgetPassword } = useAccountApi();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordForm>();

  const onSubmit = ({ email }: ForgotPasswordForm) => {
    forgetPassword.mutate(email);
  };

  const handleNotificationToggle = () => {
    forgetPassword.reset();
    toggle();
  };

  if (forgetPassword.isSuccess === true)
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ResponseStatus
          status={ResponseStatuses.success}
          onClick={handleNotificationToggle}
          title="Email sent!"
          subtitle="Check your email, and click the link to change your password."
        />
      </Modal>
    );
  if (forgetPassword.isError === true)
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ResponseStatus
          status={ResponseStatuses.error}
          onClick={handleNotificationToggle}
          subtitle="We were unable to fullfil your request. Please try again."
        />
      </Modal>
    );

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="forgot-password">
        <img
          onClick={toggle}
          className="forgot-password-cross"
          src={close}
          alt=""
        />
        <Typography
          className="forgot-password-title"
          variant={TypographyVariants.h2}
        >
          Forgot password?
        </Typography>
        <Typography
          className="forgot-password-text"
          variant={TypographyVariants.h4}
        >
          It happens! Enter the email address associated with your account.
        </Typography>
        <form name="forgot-password-form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("email", {
              required: "The email is required",
            })}
            name="email"
            placeholder="Enter email address"
            errorMessage={errors?.email?.message}
            error={!!errors.email}
          />
          <Button
            type="submit"
            variant={ButtonVariants.contained}
            disabled={forgetPassword.isLoading}
          >
            Continue
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
