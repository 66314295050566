import React from "react";
import cn from "clsx";
import "./index.css";

type FontWeights = 400 | 500 | 600 | 700 | 800 | 900;

export enum TypographyVariants {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  body1 = "body1",
  body2 = "body2",
  title = "title",
}

type TypographyComponents = "p" | "span";

export enum TypographyColors {
  primaryText = "primary-text",
  secondaryText = "secondary-text",
  error = "error",
  white = "white",
  blue = "blue",
  darkBlue = "dark-blue",
  green = "green",
  pink = "pink",
  lightGrey = "light-grey",
  lightGrey1 = "light-grey-1",
  Active = "active",
  Pending = "pending",
  Expired = "expired",
}

export enum TypographyAlignments {
  left = "left",
  right = "right",
  center = "center",
}

interface Props {
  variant?: TypographyVariants;
  component?: TypographyComponents;
  color?: TypographyColors;
  children: React.ReactNode;
  className?: string;
  bold?: boolean;
  align?: TypographyAlignments;
  fontSize?: string;
  fontWeight?: FontWeights;
  underline?: boolean;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  padding?: string;
  lineHeight?: string;
}

const Typography = ({
  variant = TypographyVariants.body1,
  color = TypographyColors.primaryText,
  children,
  className,
  bold,
  align,
  component,
  ...props
}: Props) => {
  const check = component || variant;
  const Component =
    check === TypographyVariants.body1 ||
    check === TypographyVariants.body2 ||
    check === TypographyVariants.title
      ? "p"
      : check;

  return (
    <Component
      className={cn(className, {
        [`typography--variant-${variant}`]: variant,
        [`typography--color-${color}`]: color,
        [`typography--align-${align}`]: align,
        "typography--bold": bold,
      })}
      style={{
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        textDecoration: props.underline ? "underline" : "none",
        padding: props.padding,
        paddingTop: props.paddingTop,
        paddingBottom: props.paddingBottom,
        paddingLeft: props.paddingLeft,
        paddingRight: props.paddingRight,
        lineHeight: props.lineHeight,
      }}
    >
      {children}
    </Component>
  );
};

export default Typography;
