import { createContext, ReactNode, useState } from "react";

export enum Features {
  pendingRequests = "pending requests widget",
  error = "error",
  scheduling = "scheduling",
}

interface FeatureContextType {
  features: Features[];
  setFeatures: (feature: Features[]) => void;
}

export const FeatureContext = createContext<FeatureContextType>({
  features: [],
  setFeatures: () => {},
});

export const FeatureProvider = ({ children }: { children: ReactNode }) => {
  const [features, setFeatures] = useState<Features[]>([]);

  return (
    <FeatureContext.Provider value={{ features, setFeatures }}>
      {children}
    </FeatureContext.Provider>
  );
};
