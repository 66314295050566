import Button, { ButtonVariants } from "../../library/components/Button";
import SearchTextField from "../../library/components/SearchTextField";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import AddNewPatient from "../AddNewPatient";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
import PatientPreview from "../PatientPreview";
import useModal from "../../hooks/useModal";
import Modal from "../../library/components/Modal";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../library/components/LoadingSpinner";
import ResponseStatus, {
  ResponseStatuses,
} from "../../library/components/ResponseStatus";
import {
  useMedlCirclePatients,
  usePatientSearch,
} from "../../hooks/usePatientApi";
import {
  PatientUser,
  User,
} from "@medl-repo/sdk/dist/sdk/src/types/user.types";

const PatientsAndPrescriptions = () => {
  const [searchText, setSearchText] = useState("");

  const { isOpen, toggle } = useModal();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [status, setStatus] = useState(ResponseStatuses.error);
  const [isNotificationSearchOpen, setIsNotificationSearchOpen] =
    useState(false);
  //TODO: remove the need for syncing local state with server. needed this as removing the query data did not work as intended
  const [list, setList] = useState<(User | PatientUser)[]>([]);

  const { isLoading, isError, data } = usePatientSearch({
    searchText,
    enabled: searchText.length > 0,
    successCallback: (data) => {
      setList(data);
    },
  });

  const { data: medlCirclePatients, getPatientMobile } = useMedlCirclePatients({
    onSuccess: (data) => {
      setList(data);
    },
  });

  useEffect(() => {
    if (searchText === "") {
      setList(medlCirclePatients.data || []);
    }
  }, [searchText, medlCirclePatients.data]);

  const notificationToggle = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };
  const searchNotificationToggle = () => {
    setIsNotificationSearchOpen(!isNotificationSearchOpen);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  // const getPatientMobile = (id: string, mobile: string) => {
  //   //check if the patient is in the doctor's medl circle
  //   const circle = medlCirclePatients.data?.find(
  //     (user) => user._id.toString() === id
  //   );

  //   if (circle && circle.mobile) return circle.mobile;

  //   return obstructMobileNumber(mobile);
  // };

  return (
    <div className="patients-and-prescriptions">
      <Modal isOpen={isOpen} toggle={toggle}>
        <AddNewPatient
          setStatus={setStatus}
          setIsNotificationOpen={setIsNotificationOpen}
          isOpen={isOpen}
          toggle={toggle}
        />
      </Modal>
      <Modal isOpen={isNotificationOpen} toggle={toggle} width="auto">
        <ResponseStatus
          status={status}
          onClick={notificationToggle}
          subtitle={
            status === ResponseStatuses.success
              ? "The patient was added successfully"
              : "The new patient could not be added at this time. Please try again"
          }
        />
      </Modal>
      <div className="patients__header">
        <SearchTextField
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Search Patients"
          rightNode={
            !!data?.length ? (
              <Button variant={ButtonVariants.link} onClick={handleClearSearch}>
                Clear search results
              </Button>
            ) : undefined
          }
        />
        <Button
          onClick={toggle}
          className="patients__button"
          variant={ButtonVariants.contained}
        >
          <img
            src={PlusIcon}
            alt="Plus Icon"
            className="patients__button-icon"
          />
          Add new patient
        </Button>
      </div>
      {isLoading ? (
        <div className="patients--loading">
          <LoadingSpinner subtitle="Fetching patients and prescriptions" />
        </div>
      ) : (
        <>
          <div className="patients__list-header">
            <Typography variant={TypographyVariants.h3}>
              This is your Patients and Prescriptions Screen
            </Typography>
            <Typography
              variant={TypographyVariants.h6}
              lineHeight="2rem"
              paddingTop="1rem"
            >
              Type in your patient's name, and select their profile to issue a
              new prescription, or view their medical records
            </Typography>
          </div>
          {list.length === 0 ? (
            <div className="patients--no-results">
              <Typography
                variant={TypographyVariants.h4}
                color={TypographyColors.secondaryText}
                bold
              >
                Search for a patient to see their information{" "}
              </Typography>
            </div>
          ) : (
            <div className="patients__list">
              {list.map(({ _id, fullName, dateOfBirth, mobile }) => (
                <PatientPreview
                  key={`drug-history-item-${_id}`}
                  patientId={_id}
                  fullName={fullName}
                  dateOfBirth={dateOfBirth}
                  mobile={getPatientMobile(_id.toString(), mobile || "")}
                />
              ))}
            </div>
          )}
        </>
      )}
      {isError && (
        <Modal
          isOpen={isNotificationSearchOpen}
          toggle={searchNotificationToggle}
          width="30"
        >
          <ResponseStatus
            status={ResponseStatuses.error}
            onClick={searchNotificationToggle}
            subtitle="There was an issue. Try again later."
          />
        </Modal>
      )}
    </div>
  );
};

export default PatientsAndPrescriptions;
