import { useState, useRef, useCallback } from "react";

const preventDefault = (event: React.TouchEvent | React.MouseEvent) => () => {
  if ("touches" in event) {
    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  }

  return;
};

interface LongPressParameters {
  /** function to run when a long click is detected */
  onLongPress: (event: React.TouchEvent | React.MouseEvent) => void;

  /** define how long the click duration should be. `default` is **300 ms** */
  delay?: number;

  onClick?: () => void;
  shouldPreventDefault?: boolean;
}

/**
 * detects the click duration on an html element
 */
const useLongPress = ({
  onLongPress,
  onClick = () => {},
  shouldPreventDefault = true,
  delay = 300,
}: LongPressParameters) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();
  const target = useRef<EventTarget>();

  const start = useCallback(
    (event: React.TouchEvent | React.MouseEvent) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault(event), {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event: React.TouchEvent | React.MouseEvent, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick();
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault(event));
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      start(e),
    onTouchStart: (e: React.TouchEvent) => start(e),
    onMouseUp: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => clear(e),
    onMouseLeave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      clear(e, false),
    onTouchEnd: (e: React.TouchEvent) => clear(e),
  };
};

export default useLongPress;
