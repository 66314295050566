import { FullInventoryItem } from "@medl-repo/sdk/dist/sdk/src/types/inventory.types";
import { forwardRef, useContext } from "react";
import Typography from "../../library/components/Typography";
import "./index.css";
import MedlCareIcon from "../../assets/icons/medlcare-icon.svg";
import BDSIcon from "../../assets/icons/bds-icon.svg";
import { UserContext } from "../../context/user";
import { Country } from "@medl-repo/sdk/dist/sdk/src/types/address.types";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  other: FullInventoryItem;
}

const PrescribableItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ other: item, ...props }: ItemProps, ref) => {
    const { user } = useContext(UserContext);

    return (
      <div ref={ref} {...props} className="prescribable-item">
        <div className="prescribable-item__start">
          <Typography bold={true}>{item.name}</Typography>
        </div>
        <div className="prescribable-item__end">
          {item.medlCare === true && (
            <img
              src={MedlCareIcon}
              className="prescribable-item__icon"
              alt="Medl Care Icon"
            />
          )}
          {item.bdsCode !== undefined &&
            item.bdsCode !== "" &&
            user?.country === Country.Barbados && (
              <img
                src={BDSIcon}
                className="prescribable-item__icon"
                alt="BDS Icon"
              />
            )}
        </div>
      </div>
    );
  }
);

export default PrescribableItem;
