import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import StoreIcon from "../../assets/icons/notifications/LocalPharmacyRounded.svg";
import PersonIcon from "../../assets/icons/notifications/PersonRounded.svg";
import CalendarIcon from "../../assets/icons/notifications/CalendarTodayRounded.svg";
import Box from "../../library/components/Box";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import cn from "clsx";
import "./index.css";
import { NotificationType } from "@medl-repo/sdk/dist/sdk/src/types/doctor.types";
import { useMutation } from "react-query";
import api from "../../api/medl";
import { useState } from "react";
import { Badge } from "@mui/material";

dayjs.extend(relativeTime);

interface NotificationProps {
  id: string;
  type: NotificationType;
  title: string;
  body: string;
  dateCreated: Date;
  isRead: boolean;

  /** determines the view of the notification component */
  condensed?: boolean;
}
export default function Notification({
  id,
  type,
  title,
  body,
  dateCreated,
  isRead = false,
  condensed = false,
  ...props
}: NotificationProps) {
  const [read, setRead] = useState(isRead);

  const { mutate, isLoading } = useMutation(
    () => api.doctor.markNotificationsAsRead({ notificationIds: [id] }),
    {
      onSuccess: () => {
        setRead(true);
      },
    }
  );

  const handleNotificationRead = () => {
    if (read === true) return;
    if (isLoading) return; // prevent multiple calls
    mutate();
  };

  const { icon, headerText, headerColor } = getNotificationType(type);

  return (
    <Box
      id={dateCreated.getTime().toString()}
      className="notification"
      elevation
      onMouseOver={handleNotificationRead}
      onClick={handleNotificationRead}
    >
      <div
        className={cn({
          notification__container: condensed,
        })}
      >
        <div className="notification__header">
          <img
            src={icon}
            alt="notification icon"
            className={cn("notification__icon", {
              "notification__icon--condensed": condensed,
            })}
          />
          {condensed === false && (
            <Typography variant={TypographyVariants.h4} color={headerColor}>
              {headerText}
            </Typography>
          )}

          <Badge
            variant="dot"
            color="error"
            sx={{ flex: 1 }}
            invisible={read}
          />
        </div>
        <div>
          <Typography variant={TypographyVariants.h4} bold>
            {title}
          </Typography>
          <Typography variant={TypographyVariants.h4}>{body}</Typography>
        </div>
      </div>
      <Typography
        variant={TypographyVariants.h4}
        color={TypographyColors.lightGrey}
        className="notification__date"
      >
        {dayjs(dateCreated).fromNow()}
      </Typography>
    </Box>
  );
}

const getNotificationType = (type: NotificationType) => {
  switch (type) {
    case NotificationType.Appointment:
      return {
        icon: CalendarIcon,
        headerText: "Delivery",
        headerColor: TypographyColors.green,
      };
    case NotificationType.Prescription:
      return {
        icon: StoreIcon,
        headerText: "Store",
        headerColor: TypographyColors.blue,
      };
    case NotificationType.User:
      return {
        icon: PersonIcon,
        headerText: "User",
        headerColor: TypographyColors.darkBlue,
      };
    default:
      return {
        icon: undefined,
        headerText: undefined,
        headerColor: TypographyColors.blue,
      };
  }
};
