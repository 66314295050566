import "./index.css";
import Typography from "../../library/components/Typography";
import MedlPillCapsule from "../../assets/images/two-tone-cap.png";
import Button, { ButtonVariants } from "../../library/components/Button";
import { Link } from "react-router-dom";
import { Paths } from "../../routes";

interface Props {
  type?: "404" | "generic";
}
const ErrorPage = ({ type = "generic" }: Props) => {
  let title = "Oops!";
  let subtitle = "Something went wrong";
  let body =
    "Looks like we need a prescription for some rest and recovery! Our app is toughing it out and we're working on a fix. We suggest you return to the dashboard.";

  if (type === "404") {
    title = "404";
    subtitle = "Page not found";
    body =
      "We're afraid this page has been quarantined. We suggest you go back to the dashboard.";
  }

  return (
    <div className="error-page">
      <div className="error-page__left">
        <Typography className="error-page__title">{title}</Typography>
        <Typography className="error-page__subtitle">{subtitle}</Typography>
        <Typography className="error-page__body">{body}</Typography>
        <Link to={Paths.dashboard} className="error-page__link">
          <Button
            variant={ButtonVariants.contained}
            className="error-page__button"
          >
            Take me to the dashboard
          </Button>
        </Link>
      </div>
      <div className="error-page__right">
        <img
          src={MedlPillCapsule}
          alt="Medl Pill Capsule"
          className="error-page__icon"
        />
      </div>
    </div>
  );
};

export default ErrorPage;
