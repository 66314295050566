// import "./index.css";
// import { useState } from "react";
// import useModal from "../../hooks/useModal";
// import Modal from "../../library/components/Modal";
// import ResponseStatus, {
//   ResponseStatuses,
// } from "../../library/components/ResponseStatus";
// import Typography, {
//   TypographyColors,
//   TypographyVariants,
// } from "../../library/components/Typography";
// import AddNewPatient from "../AddNewPatient";
// import Button, { ButtonVariants } from "../../library/components/Button";
// import { Controller, useForm } from "react-hook-form";
// import TextArea from "../../library/components/TextArea";
// import { TimePicker } from "antd";
// import DatePicker from "../DatePicker";
// import { Dayjs } from "dayjs";
// import { RangeValue } from "rc-picker/lib/interface";
// import Autocomplete from "../../library/components/Autocomplete";
// import { usePatientSearch } from "../../hooks/usePatientApi";
// import { User } from "@medl-repo/sdk/dist/sdk/src/types/user.types";
// import { AutocompleteItem } from "@mantine/core";
// import { capitalizeString } from "../../utils/strings";
// // import { AppointmentTypes } from "@medl-repo/sdk/dist/sdk/src/types/doctorAppointment.types";
// import Radio from "../../library/components/Radio/Radio";
// import { useAppointmentMutations } from "../../hooks/useAppointmentApi";

// interface Form {
//   name: string | null;
//   note: string | null;
//   time: RangeValue<Dayjs>;
//   date: Date | null;
//   type: AppointmentTypes | null;
// }

// const BookPatient = () => {
//   const { isOpen: isAddPatientOpen, toggle: toggleAddPatient } = useModal();
//   const { isOpen: isNotificationOpen, toggle: toggleNotification } = useModal();

//   const [status, setStatus] = useState(ResponseStatuses.error);
//   const [statusMessage, setStatusMessage] = useState("");
//   const [patient, setPatient] = useState<User | null>(null);
//   const {
//     register,
//     reset,
//     handleSubmit,
//     watch,
//     formState: { errors },
//     control,
//   } = useForm<Form>();
//   const { name } = watch();
//   const patientSearch = usePatientSearch({
//     searchText: name ?? "",
//     enabled: (name ?? "").length > 2 ? true : false,
//   });

//   const getPatientList = () => {
//     if (patientSearch.data === undefined) return [];

//     const list: AutocompleteItem[] = [];

//     patientSearch.data.forEach((patient) => {
//       if (patient.fullName === undefined) return;
//       if (patient.fullName === "") return;

//       const mobile = patient.mobile ?? "Mobile not registered";

//       list.push({
//         value: `${capitalizeString(patient.fullName)} - ${mobile}`,
//         id: patient._id.toString(),
//       });
//     });

//     return list;
//   };

//   const successCallback = () => {
//     setStatus(ResponseStatuses.success);
//     setStatusMessage("Appointment booked successfully");
//     toggleNotification();
//     resetForm();
//   };
//   const errorCallback = (message?: string) => {
//     setStatus(ResponseStatuses.error);
//     setStatusMessage(message ?? "Appointment booking failed");
//     toggleNotification();
//   };
//   const { addAppointment } = useAppointmentMutations({
//     successCallback,
//     errorCallback,
//   });

//   const resetForm = () => {
//     reset({
//       name: null,
//       note: null,
//       date: null,
//       time: null,
//       type: null,
//     });
//     setPatient(null);
//   };

//   const handleNameClear = () => {
//     reset();
//     setPatient(null);
//     patientSearch.remove();
//   };

//   const handlePatientSelect = (item: AutocompleteItem) => {
//     const user = patientSearch.data?.find(
//       (patient) => capitalizeString(patient.fullName) === item.value
//     );

//     setPatient(user || null);
//   };

//   const onSubmit = (formData: Form) => {
//     if (patient === null) throw new Error("book patient: patient is null");

//     const { date, time } = formData;
//     if (date === null)
//       throw new Error("book patient: form failed validation, date is null");
//     if (time === null || time[0] === null || time[1] === null)
//       throw new Error("book patient: form failed validation, time is null");

//     addAppointment.mutate({
//       patientId: patient?._id,
//       startTime: {
//         day: date.getUTCDate(),
//         year: date.getUTCFullYear(),
//         month: date.getUTCMonth() + 1,
//         hour: time[0].hour(),
//         minute: time[0].minute(),
//       },
//       endTime: {
//         day: date.getUTCDate(),
//         year: date.getUTCFullYear(),
//         month: date.getUTCMonth() + 1,
//         hour: time[1].hour(),
//         minute: time[1].minute(),
//       },
//       type: AppointmentTypes.InPerson,
//     });
//   };

//   return (
//     <>
//       <>
//         <div className="book-patient__header">
//           <Typography variant={TypographyVariants.h3} fontSize="1.875rem">
//             Book a patient
//           </Typography>

//           <Button
//             variant={ButtonVariants.link}
//             className="book-patient__register"
//             onClick={toggleAddPatient}
//           >
//             <Typography color={TypographyColors.blue} fontSize="1.5rem">
//               Register a new patient
//             </Typography>
//           </Button>
//         </div>

//         <form onSubmit={handleSubmit(onSubmit)}>
//           <Controller
//             control={control}
//             name="name"
//             rules={{ required: "Please select a patient" }}
//             render={({ field }) => (
//               <Autocomplete
//                 className="book-patient__input"
//                 {...field}
//                 value={field.value ?? ""}
//                 onItemSubmit={handlePatientSelect}
//                 data={getPatientList()}
//                 error={errors.name?.message}
//                 placeholder="Enter patient name here"
//                 onClear={handleNameClear}
//                 filter={() => true}
//               />
//             )}
//           />

//           <TextArea
//             className="book-patient__input"
//             placeholder="Enter patient notes here"
//             {...register("note")}
//             autoComplete="off"
//           />

//           <Typography
//             variant={TypographyVariants.h3}
//             fontSize="1.875rem"
//             className="book-patient__header"
//           >
//             Select appointment date
//           </Typography>

//           <div className="book-patient__date-picker">
//             <Controller
//               control={control}
//               name="date"
//               rules={{ required: "Please select a date for the appointment" }}
//               render={({ field }) => (
//                 <DatePicker {...field} size="xl" allowDeselect />
//               )}
//             />
//           </div>
//           {errors.date && (
//             <Typography color={TypographyColors.error}>
//               {errors.date.message}
//             </Typography>
//           )}

//           <Typography
//             variant={TypographyVariants.h3}
//             fontSize="1.875rem"
//             className="book-patient__header"
//           >
//             Appointment time
//           </Typography>

//           <Controller
//             control={control}
//             name="time"
//             rules={{ required: "Please select the time for the appointment" }}
//             render={({ field }) => (
//               <TimePicker.RangePicker
//                 className="book-patient__time"
//                 {...field}
//                 size="large"
//                 use12Hours
//                 format="h:mm a"
//                 minuteStep={15}
//               />
//             )}
//           />
//           {errors.time && (
//             <Typography color={TypographyColors.error}>
//               {errors.time.message}
//             </Typography>
//           )}

//           <Typography
//             variant={TypographyVariants.h3}
//             fontSize="1.875rem"
//             className="book-patient__header"
//           >
//             Appointment type
//           </Typography>

//           <div className="book-patient__radio">
//             <Controller
//               control={control}
//               name="type"
//               rules={{ required: "Please select an appointment type." }}
//               render={({ field }) => (
//                 <Radio
//                   {...field}
//                   value={field.value ?? ""}
//                   options={[
//                     {
//                       id: AppointmentTypes.Telephonic,
//                       value: "Telephone Appointment",
//                     },
//                     {
//                       id: AppointmentTypes.InPerson,
//                       value: "In-Person Appointment",
//                     },
//                   ]}
//                   size="lg"
//                   optionClassName="book-patient__radio"
//                   layout="row"
//                 />
//               )}
//             />
//             {errors.type && (
//               <Typography color={TypographyColors.error}>
//                 {errors.type.message}
//               </Typography>
//             )}
//           </div>

//           <Button
//             className="book-patient__button"
//             variant={ButtonVariants.contained}
//             type="submit"
//             disabled={addAppointment.isLoading}
//           >
//             <Typography color={TypographyColors.white} fontSize="1.25rem" bold>
//               Book appointment
//             </Typography>
//           </Button>
//         </form>
//       </>

//       <Modal isOpen={isAddPatientOpen} toggle={toggleAddPatient}>
//         <AddNewPatient
//           setStatus={setStatus}
//           setIsNotificationOpen={toggleNotification}
//           isOpen={isAddPatientOpen}
//           toggle={toggleAddPatient}
//           setStatusMessage={setStatusMessage}
//         />
//       </Modal>

//       <Modal isOpen={isNotificationOpen} toggle={toggleNotification} width="50">
//         <ResponseStatus
//           status={status}
//           onClick={toggleNotification}
//           subtitle={statusMessage}
//         />
//       </Modal>
//     </>
//   );
// };
// export default BookPatient;

const BookPatient = () => {
  return null;
};
export default BookPatient;
