import { useState, useEffect } from "react";
import Button, { ButtonVariants } from "../../library/components/Button";
import { Link } from "react-router-dom";

export default function CrashButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  const [count, setCount] = useState(0);
  const onClick = () => {
    setCount(count + 1);
  };
  useEffect(() => {
    if (count === 2) {
      throw new Error("I crashed!", {
        cause: {
          message: "I crashed!",
          data: count,
        },
      });
    }
  });

  return (
    <div className={props.className}>
      <Button onClick={onClick} variant={ButtonVariants.redOutlined}>
        Click Twice to Crash
      </Button>

      <Link to={"/patients-and-prescriptions/1"}>
        <Button variant={ButtonVariants.redOutlined}>Click for 404</Button>
      </Link>
    </div>
  );
}
