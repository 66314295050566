import Typography, { TypographyVariants } from "../Typography";
import ErrorNotification from "../../../assets/images/error-notification.png";
import SuccessNotification from "../../../assets/images/success-notification.gif";
import cn from "clsx";
import "./index.css";
import Button, { ButtonVariants } from "../Button";
import { ReactNode } from "react";

export enum ResponseStatuses {
  success = "success",
  error = "error",
}

export enum ResponseStatusTitles {
  success = "Success!",
  error = "Error",
}

const ResponseStatusImages = {
  success: SuccessNotification,
  error: ErrorNotification,
};

interface ResponseStatusProps {
  status: ResponseStatuses;
  onClick: () => void;
  children?: ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  button?: JSX.Element;
  showIcon?: boolean;
}

const ResponseStatus = ({
  status,
  onClick,
  className,
  title,
  subtitle,
  buttonText,
  button,
  showIcon = true,
  children,
}: ResponseStatusProps) => (
  <div className={cn(className, "response")}>
    {showIcon && (
      <img
        className={`response__icon--${status}`}
        src={ResponseStatusImages[status]}
        alt="response Status"
      />
    )}
    <Typography variant={TypographyVariants.h2}>
      {title || ResponseStatusTitles[status]}
    </Typography>
    <Typography className="response__subtitle">{subtitle}</Typography>
    {button || (
      <Button
        className="response__button"
        variant={ButtonVariants.contained}
        onClick={onClick}
      >
        {buttonText || "Close"}
      </Button>
    )}

    {children}
  </div>
);

export default ResponseStatus;
