import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import router from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { UserProvider } from "./context/user";
import { FeatureProvider } from "./context/features";
import { MantineProvider } from "@mantine/core";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <FeatureProvider>
        <UserProvider>
          <MantineProvider
            theme={{
              colors: {
                blue: [
                  "#E6EAEC",
                  "#CDD6DB",
                  "#B4C5CD",
                  "#9BB6C3",
                  "#81A9BE",
                  "#659FBD",
                  "#4698C1",
                  "#4786A5",
                  "#49768C",
                  "#476878",
                ],
              },
              primaryColor: "blue",
              fontFamily: "inherit",
            }}
          >
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </MantineProvider>
        </UserProvider>
      </FeatureProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
