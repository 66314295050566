import "./index.css";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import LeftIcon from "../../assets/icons/arrow-left-circle.svg";
import RightIcon from "../../assets/icons/arrow-right-circle.svg";
// import ScheduleItem from "../ScheduleItem/ScheduleItem";
import { TypographyAlignments } from "../../library/components/Typography";

const PendingConsults = () => {
  return (
    <div>
      <div className="pending-consults__body">
        {/* <ScheduleItem className="pending-consults__spacer" variant="pending" />
        <ScheduleItem className="pending-consults__spacer" variant="pending" />
        <ScheduleItem className="pending-consults__spacer" variant="pending" />
        <ScheduleItem className="pending-consults__spacer" variant="pending" /> */}

        <Typography
          variant={TypographyVariants.h6}
          color={TypographyColors.lightGrey1}
          align={TypographyAlignments.center}
          className="pending-consults__eof"
        >
          This is the end of the list
        </Typography>

        <div className="pending-consults__pagination">
          <img
            src={LeftIcon}
            alt={"Left Icon"}
            className="pending-consults__icon-button"
          />
          <Typography fontSize="0.75rem">Page 1 of 1</Typography>
          <img
            src={RightIcon}
            alt={"Right Icon"}
            className="pending-consults__icon-button"
          />
        </div>
      </div>
    </div>
  );
};

export default PendingConsults;
