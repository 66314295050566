import cn from "clsx";
import "./index.css";

export enum BoxColors {
  white = "white",
  grey = "grey",
}

interface BoxProps extends React.LiHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
  elevation?: boolean;
  color?: BoxColors;
}

const Box: React.FunctionComponent<BoxProps> = ({
  children,
  className,
  elevation,
  color = BoxColors.white,
  ...props
}: BoxProps) => (
  <div
    className={cn("box", className, {
      "box-elevation": elevation,
      [`box--color-${color}`]: color,
    })}
    {...props}
  >
    {children}
  </div>
);

export default Box;
