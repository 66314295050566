import Box from "../../library/components/Box";
import cn from "clsx";
import Typography, {
  TypographyAlignments,
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import { Prescription } from "@medl-repo/sdk/dist/sdk/src/types/prescription.types";
import "./index.css";
import MedlLink, { LinkColors } from "../../library/components/MedlLink";
import useModal from "../../hooks/useModal";
import { formatDate } from "../../utils/dateFormat";
import { usePrescriptionMutations } from "../../hooks/usePrescriptionApi";
import ResponseStatus, {
  ResponseStatuses,
} from "../../library/components/ResponseStatus";
import Modal from "../../library/components/Modal";
import { useEffect } from "react";

enum PrescriptionLabels {
  Active = "Active",
  Expired = "Expiring soon",
  Pending = "Pending",
}

interface PrescriptionPreviewProps {
  prescription: Prescription;
  actions?: boolean;
  actionElements?: JSX.Element;
  className?: string;
  isDrugHistoryItem?: boolean;
  condensed?: boolean;
  date?: boolean;
  status?: boolean;
}

const PrescriptionPreview = ({
  prescription,
  className,
  actions = true,
  actionElements,
  condensed,
  date,
  status,
  isDrugHistoryItem,
}: PrescriptionPreviewProps) => {
  const downloadErrorModal = useModal();
  const { downloadPrescription } = usePrescriptionMutations();

  useEffect(() => {
    if (downloadPrescription.isError === true)
      downloadErrorModal.setState(true);
  }, [downloadPrescription.isError, downloadErrorModal]);

  const handleDownloadPrescription = (prescriptionNumber: string) => () =>
    downloadPrescription.mutate({ prescriptionNumber });

  const handleDownloadErrorClick = () => {
    downloadErrorModal.setState(false);
    downloadPrescription.reset();
  };

  const renderStatus = () => {
    const { prescriptionStatus } = prescription;
    if (
      prescriptionStatus !== "Active" &&
      prescriptionStatus !== "Pending" &&
      prescriptionStatus !== "Expired"
    )
      return null;

    if (status === false) return null;

    return (
      <Typography
        variant={TypographyVariants.body2}
        bold
        color={TypographyColors[prescriptionStatus]}
      >
        {PrescriptionLabels[prescriptionStatus]}
      </Typography>
    );
  };

  const renderDetails = () => {
    if (condensed === true) return null;
    return (
      <>
        {prescription.prescribedAmount !== 0 && (
          <div className="prescription__inline-info">
            <Typography
              bold
              variant={TypographyVariants.body2}
              className="prescription__inline-label"
            >
              Amount per dose:
            </Typography>
            <Typography
              variant={TypographyVariants.body2}
              color={TypographyColors.secondaryText}
            >
              {prescription.prescribedAmount.toString()}
            </Typography>
          </div>
        )}
        {prescription.route && (
          <div className="prescription__inline-info">
            <Typography
              bold
              variant={TypographyVariants.body2}
              className="prescription__inline-label"
            >
              Route:
            </Typography>
            <Typography
              variant={TypographyVariants.body2}
              color={TypographyColors.secondaryText}
            >
              {prescription.route}
            </Typography>
          </div>
        )}
        {prescription.refill && (
          <div className="prescription__inline-info">
            <Typography
              bold
              variant={TypographyVariants.body2}
              className="prescription__inline-label"
            >
              Refills:
            </Typography>
            <Typography
              variant={TypographyVariants.body2}
              color={TypographyColors.secondaryText}
            >
              {prescription.refill}
            </Typography>
          </div>
        )}
        {prescription.notes && (
          <div className="prescription__inline-info">
            <Typography
              bold
              variant={TypographyVariants.body2}
              className="prescription__inline-label"
            >
              Note:
            </Typography>
            <Typography
              variant={TypographyVariants.body2}
              color={TypographyColors.secondaryText}
            >
              {prescription.notes}
            </Typography>
          </div>
        )}
      </>
    );
  };

  const renderDate = () => {
    if (condensed === true) return null;
    if (date === false || date === undefined) return null;

    return (
      <>
        <div className="prescription__inline-info">
          <Typography
            variant={TypographyVariants.body2}
            bold
            className="prescription__inline-label"
          >
            Date Created:
          </Typography>
          <Typography variant={TypographyVariants.body2}>
            {formatDate(new Date(prescription.createdAt * 1000 || 0))}
          </Typography>
        </div>
      </>
    );
  };

  const renderActions = () => {
    if (actions === false) return null;
    if (actionElements !== undefined) return actionElements;
    return (
      <div className="prescription__links">
        <MedlLink
          to={`/patients-and-prescriptions/prescription-issuance/${prescription.patientId}`}
          color={LinkColors.blue}
          state={{ prescription, action: "reissue" }}
        >
          <Typography
            color={TypographyColors.blue}
            variant={
              isDrugHistoryItem
                ? TypographyVariants.body1
                : TypographyVariants.body2
            }
          >
            Reissue Drug
          </Typography>
        </MedlLink>
        {isDrugHistoryItem === true && (
          <MedlLink
            to="#"
            color={LinkColors.blue}
            onClick={handleDownloadPrescription(
              prescription.prescriptionNumber
            )}
          >
            <Typography
              color={TypographyColors.blue}
              variant={TypographyVariants.body1}
              align={TypographyAlignments.right}
            >
              Download prescription
            </Typography>
          </MedlLink>
        )}
      </div>
    );
  };

  return (
    <Box
      className={cn(
        className,
        "prescription-preview",
        `prescription-preview--${prescription.prescriptionStatus}`
      )}
    >
      <div className="preview--content">
        <div>
          <Typography variant={TypographyVariants.body1} bold>
            {prescription.prescName}
          </Typography>
          <Typography
            color={TypographyColors.secondaryText}
            variant={TypographyVariants.body2}
          >
            {prescription.prescribableName}
          </Typography>
          <Typography
            color={TypographyColors.secondaryText}
            variant={TypographyVariants.body2}
          >
            {prescription.recommendedFormulation}
          </Typography>

          {renderStatus()}

          {renderDetails()}

          {renderDate()}
        </div>

        {renderActions()}
      </div>

      <Modal {...downloadErrorModal}>
        <ResponseStatus
          onClick={handleDownloadErrorClick}
          status={ResponseStatuses.error}
          title="Download failed"
          subtitle="This prescription cannot be downloaded at this time, please try again later."
          buttonText="Okay got it"
          showIcon={false}
        />
      </Modal>
    </Box>
  );
};

export default PrescriptionPreview;
