import Button, { ButtonVariants } from "../../library/components/Button";
import Select from "../../library/components/Select";
import TextArea from "../../library/components/TextArea";
import TextField from "../../library/components/TextField";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
import PhoneIcon from "../../assets/icons/phone.svg";
import MailIcon from "../../assets/icons/envelope.svg";
import MarkerIcon from "../../assets/icons/map-marker.svg";
import CheckIcon from "../../assets/icons/check-solid.svg";
import { Controller, useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { useContext } from "react";
import { UserContext } from "../../context/user";
import { useMutation } from "react-query";
import api from "../../api/medl";

interface HelpForm {
  email: string;
  subject: string;
  body: string;
}

const Help = () => {
  const { user } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
    control,
  } = useForm<HelpForm>();

  const { mutate, isLoading, isSuccess, reset } = useMutation(
    (params: HelpForm) =>
      api.doctor.sendHelpMessage(params.email, params.subject, params.body),
    {
      onSuccess(resp) {
        resetForm();
        setTimeout(reset, 1200);
      },
    }
  );

  const onSubmit = (formData: HelpForm) => {
    mutate(formData);
  };

  if (user === null) return null;
  return (
    <div className="help">
      <div className="help__column">
        <Typography
          variant={TypographyVariants.h1}
          color={TypographyColors.blue}
        >
          Contact Us
        </Typography>
        <Typography variant={TypographyVariants.h3} className="help-sub-title">
          Our support team would love to hear from you!
        </Typography>
        <div className="contact-info">
          <div className="contact-info__row">
            <Typography variant={TypographyVariants.title}>
              Want to speak directly with a member of our team?
            </Typography>
          </div>
          <div className="contact-info__row">
            <img
              src={PhoneIcon}
              alt="Phone Icon"
              className="contact-info__icon"
            />
            <div>
              <Typography variant={TypographyVariants.h4}>
                +1 (868) 610 6335
              </Typography>

              <Typography variant={TypographyVariants.h4}>
                +1 (868) 610 3587
              </Typography>
            </div>
          </div>
          <div className="contact-info__row">
            <img
              src={MailIcon}
              alt="Email Icon"
              className="contact-info__icon"
            />

            <Typography variant={TypographyVariants.h4}>
              hello@medl.co
            </Typography>
          </div>
          <div className="contact-info__row">
            <img
              src={MarkerIcon}
              alt="Address Icon"
              className="contact-info__icon"
            />

            <Typography variant={TypographyVariants.h4}>
              16 Kelly Kenny St, Woodbrook
            </Typography>
          </div>
        </div>
      </div>
      <div className="help__column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            defaultValue={user.email}
            {...register("email", {
              required: "The email is required",
              validate: {
                email: (value) => isEmail(value) || "*Incorrect Email Format",
              },
            })}
            className="help__form-input"
            errorMessage={errors?.email?.message}
            error={!!errors.email}
          />
          <Controller
            control={control}
            name={"subject"}
            rules={{ required: "Please select a description" }}
            render={({ field }) => (
              <Select
                placeholder="Description"
                options={[
                  "Prescriptions",
                  "Medication",
                  "Tech Support",
                  "Other",
                ]}
                {...field}
                errorMessage={errors?.subject?.message}
                error={!!errors.subject}
              />
            )}
          />
          <TextArea
            {...register("body", {
              required: "The message is required",
            })}
            className="help__form-input"
            errorMessage={errors?.body?.message}
            error={!!errors.body}
            placeholder="Let us know more..."
            autoComplete="off"
          />
          {isSuccess ? (
            <div className="help__success-container">
              <Typography>Message sent</Typography>
              <img
                src={CheckIcon}
                alt="Check Icon"
                className="help__success-icon"
              />
            </div>
          ) : (
            <Button
              type="submit"
              variant={ButtonVariants.contained}
              disabled={isLoading}
            >
              Send Message
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Help;
