import { useLocation, Link } from "react-router-dom";
import cn from "clsx";
import DashboardIconSelected from "../../assets/icons/menu/dashboard-icon-selected.svg";
import DashboardIcon from "../../assets/icons/menu/dashboard-icon.svg";
import NotificationIconSelected from "../../assets/icons/menu/notifications-icon-selected.svg";
import NotificationIcon from "../../assets/icons/menu/notifications-icon.svg";
import PatientsIcon from "../../assets/icons/menu/patients-icon.svg";
import PatientsIconSelected from "../../assets/icons/menu/patients-icon-selected.svg";
import HelpIcon from "../../assets/icons/menu/help-icon.svg";
import HelpIconSelected from "../../assets/icons/menu/help-icon-selected.svg";
import EditProfileIcon from "../../assets/icons/menu/edit-profile-icon.svg";
import EditProfileIconSelected from "../../assets/icons/menu/edit-profile-icon-selected.svg";
import LogOutIcon from "../../assets/icons/menu/log-out-icon.svg";
import MedlLogoLight from "../../assets/icons/medl-logo-light.svg";
import HamburgerIcon from "../../assets/icons/menu/hamburger-icon.svg";
import CalendarIcon from "../../assets/icons/menu/calendar.svg";
import CalendarIconSelected from "../../assets/icons/menu/calendar-selected.svg";
import Typography, {
  TypographyColors,
} from "../../library/components/Typography";
import { Paths } from "../../routes";
import "./index.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../library/components/ConfirmationModal";
import useModal from "../../hooks/useModal";
import api from "../../api/medl";
import ResponseStatus, {
  ResponseStatuses,
} from "../../library/components/ResponseStatus";
import Modal from "../../library/components/Modal";
import Feature from "../Feature/Feature";
import { Features } from "../../context/features";

const SideNavBar = () => {
  const navigate = useNavigate();
  const { isOpen, toggle } = useModal();
  const { pathname } = useLocation();

  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const handleMenuToggle = () => {
    setShow((state) => !state);
  };

  const notificationToggle = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const handleLogOut = async () => {
    try {
      await api.logout();
      toggle();
      navigate(Paths.signIn);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <div className="side-navbar">
      <div className={cn("box-container", { "box-container--show": show })}>
        <div className="side-navbar__medl-logo">
          <img
            src={MedlLogoLight}
            alt="Medl Logo"
            className="medl-logo-navbar"
          />
          <img
            src={HamburgerIcon}
            alt="Medl Logo"
            className="side-navbar__hamburger"
            onClick={handleMenuToggle}
          />
        </div>
        <div className={cn({ "side-navbar__list": !show })}>
          <Link
            className="side-navbar-list-item"
            to={Paths.dashboard}
            onClick={handleMenuToggle}
          >
            <img
              src={
                pathname === Paths.dashboard
                  ? DashboardIconSelected
                  : DashboardIcon
              }
              alt="Dashboad Icon"
              className="list-item-logo"
            />
            <Typography
              color={
                pathname === Paths.dashboard
                  ? TypographyColors.blue
                  : TypographyColors.white
              }
            >
              Dashboard
            </Typography>
          </Link>

          <Link
            className="side-navbar-list-item"
            to={Paths.patientsAndPrescriptions}
            onClick={handleMenuToggle}
          >
            <img
              src={
                pathname.includes(Paths.patientsAndPrescriptions)
                  ? PatientsIconSelected
                  : PatientsIcon
              }
              alt="Patients Icon"
              className="list-item-logo"
            />
            <Typography
              color={
                pathname.includes(Paths.patientsAndPrescriptions)
                  ? TypographyColors.blue
                  : TypographyColors.white
              }
            >
              Patients and Prescriptions
            </Typography>
          </Link>

          <Feature
            name={Features.scheduling}
            on={
              <Link
                className="side-navbar-list-item"
                to={"/bookings/consults"}
                onClick={handleMenuToggle}
              >
                <img
                  src={
                    pathname.includes(Paths.bookings)
                      ? CalendarIconSelected
                      : CalendarIcon
                  }
                  alt="Calendar Icon"
                  className="list-item-logo"
                />
                <Typography
                  color={
                    pathname.includes(Paths.bookings)
                      ? TypographyColors.blue
                      : TypographyColors.white
                  }
                >
                  Bookings
                </Typography>
              </Link>
            }
          />

          <Link
            className="side-navbar-list-item"
            to={Paths.notifications}
            onClick={handleMenuToggle}
          >
            <img
              src={
                pathname === Paths.notifications
                  ? NotificationIconSelected
                  : NotificationIcon
              }
              alt="Dashboard Icon"
              className="list-item-logo"
            />
            <Typography
              color={
                pathname === Paths.notifications
                  ? TypographyColors.blue
                  : TypographyColors.white
              }
            >
              Notifications
            </Typography>
          </Link>

          <Link
            className="side-navbar-list-item"
            to={Paths.account}
            onClick={handleMenuToggle}
          >
            <img
              src={
                pathname.includes(Paths.account)
                  ? EditProfileIconSelected
                  : EditProfileIcon
              }
              alt="Patients Icon"
              className="list-item-logo"
            />
            <Typography
              color={
                pathname.includes(Paths.account)
                  ? TypographyColors.blue
                  : TypographyColors.white
              }
            >
              Edit Account
            </Typography>
          </Link>

          <Link
            className="side-navbar-list-item"
            to={Paths.help}
            onClick={handleMenuToggle}
          >
            <img
              src={pathname.includes(Paths.help) ? HelpIconSelected : HelpIcon}
              alt="Help Icon"
              className="list-item-logo"
            />
            <Typography
              color={
                pathname.includes(Paths.help)
                  ? TypographyColors.blue
                  : TypographyColors.white
              }
            >
              Help
            </Typography>
          </Link>
          <div className="side-navbar-list-item" onClick={toggle}>
            <img src={LogOutIcon} alt="Log out" className="list-item-logo" />
            <Typography color={TypographyColors.white}>Log Out</Typography>
          </div>
        </div>
      </div>

      <ConfirmationModal
        toggle={toggle}
        isOpen={isOpen}
        handleConfirm={handleLogOut}
        subtitle="Are you sure you want to log out?"
        primaryActionText="Log out"
        title="Log Out"
      />

      {error && (
        <Modal isOpen={true} toggle={notificationToggle} width="50">
          <ResponseStatus
            status={ResponseStatuses.error}
            onClick={notificationToggle}
            subtitle="There was an issue. Try again later."
          />
        </Modal>
      )}
    </div>
  );
};

export default SideNavBar;
