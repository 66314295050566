import MedlLink from "../../library/components/MedlLink";
import { LinkProps } from "react-router-dom";
import "./index.css";
import Typography, {
  TypographyColors,
} from "../../library/components/Typography";

interface RedirectLinkProps extends LinkProps {
  className?: string;
  src: string;
  label: string;
  color: TypographyColors;
}

const RedirectLink = ({
  className,
  src,
  label,
  color,
  ...props
}: RedirectLinkProps) => (
  <div className={className}>
    <MedlLink color={color} {...props}>
      <img src={src} alt={label} className="link__icon" />
      <Typography color={color}>{label}</Typography>
    </MedlLink>
  </div>
);

export default RedirectLink;
