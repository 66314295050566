import { useNavigate } from "react-router-dom";
import MedlLink from "../MedlLink";
import "./index.css";

export const GoBackLink = () => {
  const navigate = useNavigate();
  return (
    <div className="link--layout">
      <MedlLink className="back-link" onClick={() => navigate(-1)} to={""}>
        &lt; Back
      </MedlLink>
    </div>
  );
};
