import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import { ModalProps } from "../Modal";

interface ConfirmationModalProps extends ModalProps {
  className?: string;
  title: string;
  subtitle: string;
  primaryActionText?: string;
  secondaryActionText?: string;
  handleConfirm: () => void;
}

const ConfirmationModal = ({
  title,
  subtitle,
  toggle,
  handleConfirm,
  isOpen,
  primaryActionText,
  secondaryActionText,
}: ConfirmationModalProps) => (
  <Dialog open={isOpen} onClose={toggle}>
    <DialogTitle textAlign={"center"}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{subtitle}</DialogContentText>
    </DialogContent>
    <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
      <Button variant="outlined" onClick={toggle}>
        {secondaryActionText || "Cancel"}
      </Button>
      <Button
        variant="contained"
        autoFocus
        onClick={handleConfirm}
        sx={{ color: "common.white" }}
      >
        {primaryActionText || "Confirm"}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationModal;
