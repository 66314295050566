import { forwardRef } from "react";
import { SelectOption } from "../Select/Select";
import Typography from "../Typography";
import "./index.css";
import {
  Autocomplete as MantineAutocomplete,
  AutocompleteProps,
  SelectItemProps,
} from "@mantine/core";
import CloseIcon from "../../../assets/icons/close.svg";
import cn from "clsx";

export type AutocompleteOption = SelectOption;

interface Props extends AutocompleteProps {
  onClear?: () => void;
}
const Autocomplete = forwardRef<HTMLInputElement, Props>(
  ({ onClear, ...props }, ref) => {
    const renderClearButton = () => {
      if (props.value === undefined || props.value === "") return null;
      return (
        <img
          src={CloseIcon}
          alt="clear icon"
          className="autocomplete__clear"
          onClick={() => {
            props.onChange && props.onChange("");
            onClear && onClear();
          }}
        />
      );
    };

    return (
      <MantineAutocomplete
        ref={ref}
        {...props}
        variant={"unstyled"}
        rightSection={props.rightSection ?? renderClearButton()}
        className={cn("autocomplete", props.className)}
        limit={100}
        maxDropdownHeight="160px"
        itemComponent={props.itemComponent ?? AutoCompleteItem}
      />
    );
  }
);

export default Autocomplete;

const AutoCompleteItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ value, id, ...others }: SelectItemProps, ref) => (
    <div ref={ref} {...others} key={id}>
      <Typography>{value}</Typography>
    </div>
  )
);
