import { UseQueryOptions, useQuery, useQueryClient } from "react-query";
import api from "../api/medl";
import {
  PatientUser,
  User,
} from "@medl-repo/sdk/dist/sdk/src/types/user.types";

interface Params {
  searchText: string;
  enabled?: boolean;
  successCallback?: (data: User[]) => void;
  errorCallback?: (error: any) => void;
}
export const usePatientSearch = ({
  searchText,
  enabled,
  successCallback,
  errorCallback,
}: Params) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, refetch } = useQuery(
    ["patients-search", searchText],
    async () => await api.doctor.searchForPatients(searchText),
    {
      enabled: enabled,
      onSuccess: (data) => {
        successCallback && successCallback(data);
      },
      onError: (error: any) => {
        errorCallback && errorCallback(error);
      },
      refetchOnMount: false,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const remove = () => {
    queryClient.removeQueries("patients-search");
  };

  return {
    data,
    isLoading,
    isError,
    refetch,
    remove,
  };
};

export const useMedlCirclePatients = (
  options?: UseQueryOptions<PatientUser[], unknown, PatientUser[], string[]>
) => {
  const medlCirclePatients = useQuery(
    ["medl-circle-patients"],
    () => api.doctor.getMedlCirclePatients(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      ...options,
    }
  );

  /** given a mobile number, will obstruct the middle 4 digits with asterisks */
  const _obstructMobileNumber = (mobile: string) => {
    if (mobile.length < 11) return mobile;
    return mobile.replace(/(\d{4})(\d{4})(\d{3})/, "$1****$3");
  };

  const getPatientMobile = (patientId: string, mobile: string) => {
    const circle = medlCirclePatients.data?.find(
      (user) => user._id.toString() === patientId
    );

    if (circle && circle.mobile) return circle.mobile;

    return _obstructMobileNumber(mobile);
  };

  return { data: medlCirclePatients, getPatientMobile };
};
