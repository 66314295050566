import { Outlet } from "react-router-dom";
import SideNavBar from "../SideNavBar";
import "./index.css";

export const Page = () => {
  return (
    <div className="page-container">
      <SideNavBar />
      <div className="outlet-container">
        <Outlet />
      </div>
    </div>
  );
};

export default Page;
