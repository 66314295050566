import { useMutation, useQueryClient } from "react-query";
import api from "../api/medl";

export const useAccountApi = () => {
  const queryClient = useQueryClient();
  const forgetPassword = useMutation(
    (email: string) => api.doctorForgotPassword(email),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
      },
    }
  );

  return { forgetPassword };
};
