import Typography from "../../library/components/Typography";
import "./index.css";
import { Outlet } from "react-router-dom";
import PlusCircle from "../../assets/icons/plus-circle-icon-black.svg";
import Button, {
  ButtonSizes,
  ButtonVariants,
} from "../../library/components/Button";
import "./index.css";
import NavLink from "../../library/components/NavLink";

const Consults = () => {
  return (
    <div className="consults">
      <div className="consults__header">
        <NavLink to="" end>
          {({ isActive }) => (
            <Typography
              fontSize="1.5rem"
              fontWeight={isActive ? 700 : undefined}
              underline={isActive ?? undefined}
            >
              Pending Requests (4)
            </Typography>
          )}
        </NavLink>

        <NavLink to="scheduled" className="consults__sub-header--space">
          {({ isActive }) => (
            <Typography
              fontSize="1.5rem"
              fontWeight={isActive ? 700 : undefined}
              underline={isActive ?? undefined}
            >
              Scheduled Consults
            </Typography>
          )}
        </NavLink>

        <div>
          <NavLink to="create">
            <Button
              variant={ButtonVariants.outlined}
              size={ButtonSizes.small}
              className="consults__book-button"
              color="neutral"
            >
              <img
                src={PlusCircle}
                alt={"Plus Icon"}
                className="book-button__icon"
              />
              <Typography fontSize="1.25rem" fontWeight={400}>
                Book a patient
              </Typography>
            </Button>
          </NavLink>
        </div>
      </div>

      <div className="consults__spacer" />

      <Outlet />
    </div>
  );
};

export default Consults;
