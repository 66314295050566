import { Paths } from ".";
import { Navigate } from "react-router-dom";
import api from "../api/medl";
import { useContext, useEffect } from "react";
import { UserContext } from "../context/user";
import { useQuery } from "react-query";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const auth = useContext(UserContext);

  const {
    data: user,
    error,
    isLoading,
  } = useQuery("user", async () => api.doctor.viewDetails(), {
    retry: false,
  });

  useEffect(() => {
    auth.setUser(user || null);
  }, [auth, user]);

  if (auth.user === null) {
    if (isLoading === true) return null;
    if (user === undefined) return <Navigate to={Paths.signIn} />;
    if (error) return <Navigate to={Paths.signIn} />;
  }

  return children;
};

export default ProtectedRoute;
