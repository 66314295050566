import Box from "../../library/components/Box";
import MedicalHistory from "../../assets/icons/medical-history-icon.svg";
import PlusCircle from "../../assets/icons/plus-circle-icon.svg";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
import { Paths } from "../../routes";
import { formatDate } from "../../utils/dateFormat";
import { ObjectId } from "bson";
import RedirectLink from "../RedirectLink";

interface PatientPreviewProps {
  patientId: ObjectId;
  fullName: string;
  dateOfBirth: number;
  mobile: string;
}

const PatientPreview = ({
  patientId,
  fullName,
  dateOfBirth,
  mobile,
}: PatientPreviewProps) => (
  <div className="patient-item">
    <Box className="patient-box">
      <div className="patient__info">
        <Typography variant={TypographyVariants.h4} bold>
          {fullName}
        </Typography>
        <Typography color={TypographyColors.secondaryText}>
          Date of birth: {formatDate(new Date(dateOfBirth))}
        </Typography>
        <Typography color={TypographyColors.secondaryText}>
          Phone number: {mobile}
        </Typography>
      </div>
      <div className="patient__links">
        <RedirectLink
          src={PlusCircle}
          to={`${Paths.prescriptionIssuance}/${patientId}`}
          color={TypographyColors.blue}
          label="Issue prescription"
        />
        <RedirectLink
          src={MedicalHistory}
          to={`${Paths.medicalHistory}/${patientId}`}
          color={TypographyColors.pink}
          label="View medical history"
        />
      </div>
    </Box>
  </div>
);

export default PatientPreview;
