import Typography, {
  TypographyVariants,
  TypographyColors,
} from "../../library/components/Typography";
import "./index.css";

const SignInPoster = () => (
  <div className="register-poster">
    <div className="register-poster__footer">
      <Typography
        variant={TypographyVariants.h2}
        className="register-poster__footer-title"
        color={TypographyColors.white}
      >
        Care
      </Typography>
      <Typography
        variant={TypographyVariants.h4}
        color={TypographyColors.white}
      >
        Medl delivers your medicine to your door, freeing you up to enjoy the
        things that make life worth living.
      </Typography>
    </div>
  </div>
);

export default SignInPoster;
