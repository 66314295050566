import React, { forwardRef } from "react";
import Typography from "../Typography";
import cn from "clsx";
import "./index.css";

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  errorMessage?: string;
  error?: boolean;
}
const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ className, errorMessage, error, ...props }, ref) => {
    return (
      <div ref={ref} className={cn("input__container", className)}>
        <input
          className={cn("input", {
            "input--error": error,
          })}
          {...props}
        />
        {error && (
          <Typography className="input__error-label">{errorMessage}</Typography>
        )}
      </div>
    );
  }
);

export default TextField;
