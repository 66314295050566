import Modal, { ModalProps } from "../../library/components/Modal";

import { Prescription } from "@medl-repo/sdk/dist/sdk/src/types/prescription.types";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import PrescriptionPreview from "../PrescriptionPreview";
import { Divider } from "@mantine/core";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import api from "../../api/medl";
import { Paths } from "../../routes";

interface Props extends ModalProps {
  summaryPrescriptions: Prescription[] | undefined;
}

const PatientPrescriptionsModal = ({
  toggle,
  isOpen,
  summaryPrescriptions,
}: Props) => {
  const { patientId } = useParams();

  const { data: history } = useQuery(
    ["all-prescription-history", patientId],
    async () =>
      await api.doctor.getPatientPrescriptions(
        patientId!,
        undefined,
        undefined,
        undefined
      ),
    {
      retry: (failureCount, error: Error) => {
        if (
          error.stack?.includes("Received Status Code: 403") ||
          failureCount > 2
        ) {
          return false;
        }

        return true;
      },
      enabled: patientId !== undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  if (!patientId) return null;
  if (isOpen === false) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Stack>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          divider={<Divider />}
          spacing={2}
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              maxHeight: "525px",
              overflow: "auto",
            },
          })}
        >
          <Box>
            <Typography variant="h5">Patient Active Prescriptions</Typography>
            {summaryPrescriptions && summaryPrescriptions.length > 0 ? (
              <Box
                sx={{
                  maxHeight: "525px",
                  overflow: "auto",
                }}
              >
                {summaryPrescriptions.map((prescription) => (
                  <PrescriptionPreview
                    key={prescription._id.toString()}
                    prescription={prescription}
                    condensed
                  />
                ))}
              </Box>
            ) : (
              <Alert severity="info" sx={{ margin: "2rem 0" }}>
                No active prescriptions
              </Alert>
            )}
          </Box>

          <Box>
            <Typography variant="h5">Medical History</Typography>
            <Box
              sx={{
                maxHeight: "525px",
                overflow: "auto",
              }}
            >
              {history && history.data.length > 0 ? (
                history.data.map((prescription) => (
                  <PrescriptionPreview
                    key={prescription._id.toString()}
                    prescription={prescription}
                    condensed
                  />
                ))
              ) : (
                <Alert severity="info" sx={{ margin: "2rem 0" }}>
                  No prescription history
                </Alert>
              )}
            </Box>
          </Box>
        </Stack>

        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
          <Button variant="contained" color="error" onClick={toggle}>
            Close
          </Button>
          <Button
            variant="contained"
            component={Link}
            to={`${Paths.medicalHistory}/${patientId}`}
          >
            View Items
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default PatientPrescriptionsModal;
