import TextField from "../../library/components/TextField";
import { PartialPrescriptionFormProps } from "./DivisibleForm";

const NonDivisibleForm = ({
  register,
  errors,
  skuDescription,
}: PartialPrescriptionFormProps) => {
  return (
    <div className="issue__fields--inline">
      <div className="issue__fields--half-width issue__fields--left">
        <TextField
          placeholder="Number of units"
          {...register("units", {
            required: "The number of units is required",
          })}
          errorMessage={errors.units?.message}
          error={!!errors.units}
          autoComplete="off"
          type="number"
        />
      </div>
      <div className="issue__fields--half-width">
        <TextField disabled={true} value={skuDescription} />
      </div>
    </div>
  );
};

export default NonDivisibleForm;
