import { useState } from "react";

export default function useModal(initial = false) {
  const [isOpen, setIsOpen] = useState(initial);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    toggle,
    setState: setIsOpen,
  };
}
