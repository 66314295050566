// import { useState, useEffect, ChangeEvent } from "react";
// import Button from "../../library/components/Button";
// import Typography from "../../library/components/Typography";
// import "./index.css";
// import api from "../../api/medl";
// import trashIcon from "../../assets/icons/trash-icon.svg";
// import {
//   DoctorAvailability,
//   SchedulePlan,
//   WeekDays,
// } from "@medl-repo/sdk/dist/sdk/src/types/doctor.types";
// import { AppointmentTypes } from "@medl-repo/sdk/dist/sdk/src/types/doctorAppointment.types";
// import { TimePicker } from "antd";
// import dayjs, { Dayjs } from "dayjs";
// import { useMutation, useQuery, useQueryClient } from "react-query";
// import Checkbox from "../../library/components/Checkbox";
// import Modal from "../../library/components/Modal";
// import ResponseStatus, {
//   ResponseStatuses,
// } from "../../library/components/ResponseStatus";
// import { RangeValue } from "rc-picker/lib/interface";
// import useModal from "../../hooks/useModal";

// export interface ScheduleFront {
//   hour: number | null;
//   minute: number | null;
// }

// export interface SchedulePlanFront {
//   start: ScheduleFront;
//   end: ScheduleFront;
// }

// export interface DoctorAvailabilityront {
//   day: WeekDays;
//   schedule: SchedulePlanFront[];
// }

// export interface typeAvailability extends DoctorAvailabilityront {
//   available: boolean;
//   error?: boolean;
// }

// const initialList: typeAvailability[] = [
//   {
//     day: WeekDays.Sunday,
//     available: false,
//     schedule: [],
//   },
//   {
//     day: WeekDays.Monday,
//     available: false,
//     schedule: [],
//   },
//   {
//     day: WeekDays.Tuesday,
//     available: false,
//     schedule: [],
//   },
//   {
//     day: WeekDays.Wednesday,
//     available: false,
//     schedule: [],
//   },
//   {
//     day: WeekDays.Thursday,
//     available: false,
//     schedule: [],
//   },
//   {
//     day: WeekDays.Friday,
//     available: false,
//     schedule: [],
//   },
//   {
//     day: WeekDays.Saturday,
//     available: false,
//     schedule: [],
//   },
// ];

// const Availability = () => {
//   const queryClient = useQueryClient();
//   const { isOpen, toggle } = useModal();
//   const [availability, setAvailability] =
//     useState<typeAvailability[]>(initialList);
//   const [disabledButton, setDisabledButton] = useState<boolean>(true);
//   const [typeAppointment, setTypeAppointment] = useState<AppointmentTypes>(
//     AppointmentTypes.InPerson
//   );
//   const { data } = useQuery("get-availabilities", () =>
//     api.doctor.getAvailability()
//   );

//   const editAvailableAppointment = useMutation(
//     () => {
//       const newAvailability: DoctorAvailability[] = pushFormattedAvailability();
//       return api.doctor.addAvailability(newAvailability);
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries("get-availabilities");
//         toggle();
//       },
//       onError: () => {
//         toggle();
//       },
//     }
//   );

//   const getAvailabilityFormatted = () => {
//     if (!data) return;
//     const newList = [...availability];
//     data.data.forEach((item) => {
//       const itemAvailability = newList.find(
//         (Availability) => Availability.day === item.day
//       );
//       if (itemAvailability) {
//         itemAvailability.available = true;
//         itemAvailability.schedule = item.schedule;
//       }
//     });
//     setAvailability([...newList]);
//   };

//   useEffect(() => {
//     getAvailabilityFormatted();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [data]);

//   const pushFormattedAvailability = () => {
//     const newAvailability: DoctorAvailability[] = [...availability]
//       .filter((item) => item.available && !item.error)
//       .map((item) => {
//         const newSchedule: SchedulePlan[] = [];
//         item.schedule.forEach((schedule) => {
//           if (
//             schedule.start.hour !== null &&
//             schedule.start.minute !== null &&
//             schedule.end.hour !== null &&
//             schedule.end.minute !== null
//           ) {
//             newSchedule.push({
//               start: {
//                 hour: schedule.start.hour,
//                 minute: schedule.start.minute,
//               },
//               end: {
//                 hour: schedule.end.hour,
//                 minute: schedule.end.minute,
//               },
//             });
//           }
//         });
//         const newItem: DoctorAvailability = {
//           day: WeekDays[item.day],
//           schedule: newSchedule,
//         };
//         return newItem;
//       });
//     return newAvailability;
//   };

//   const handleCheck = (index: number, available: boolean) => () => {
//     const newList = [...availability];
//     const current = newList.find((item, indexItem) => indexItem === index);
//     if (!current) return;
//     current.available = available;
//     setDisabledButton(false);
//     setAvailability([...newList]);
//   };

//   const handleUpdateTime =
//     (index: number, indexSchedule: number) =>
//     (e: RangeValue<Dayjs>, timeString: RangeValue<String>) => {
//       setDisabledButton(false);
//       if (e == null || e === undefined) {
//         const newList = [...availability];
//         newList[index].schedule[indexSchedule].start.hour = null;
//         newList[index].schedule[indexSchedule].start.minute = null;
//         newList[index].schedule[indexSchedule].end.hour = null;
//         newList[index].schedule[indexSchedule].end.hour = null;
//         setAvailability([...newList]);
//         validateTime(
//           newList[index].schedule[indexSchedule],
//           index,
//           indexSchedule
//         );
//         setDisabledButton(true);
//         return;
//       }
//       if (e[0] == null || e[0] === undefined) return;
//       if (e[1] == null || e[1] === undefined) return;
//       const startHour = e[0].hour();
//       const startMinute = e[0].minute();
//       const endHour = e[1].hour();
//       const endMinute = e[1].minute();
//       const newList = [...availability];
//       const currentSchedule = newList[index].schedule[indexSchedule];
//       currentSchedule.start.hour = startHour;
//       currentSchedule.start.minute = startMinute;
//       currentSchedule.end.hour = endHour;
//       currentSchedule.end.minute = endMinute;
//       validateTime(currentSchedule, index, indexSchedule);
//     };

//   const validateTime = (
//     currentSchedule: SchedulePlanFront,
//     index: number,
//     indexSchedule: number
//   ): boolean => {
//     if (!currentSchedule) return true;
//     const newList = [...availability];
//     const scheduleStart = dayjs(
//       `${currentSchedule.start.hour}:${currentSchedule.start.minute}`,
//       "HH:mm"
//     ).unix();
//     const scheduleEnd = dayjs(
//       `${currentSchedule.end.hour}:${currentSchedule.end.minute}`,
//       "HH:mm"
//     ).unix();
//     const scheduleResp = newList[index].schedule.some((item, indexItem) => {
//       const appointmentStart = dayjs(
//         `${item.start.hour}:${item.start.minute}`,
//         "HH:mm"
//       ).unix();
//       const appointmentEnd = dayjs(
//         `${item.end.hour}:${item.end.minute}`,
//         "HH:mm"
//       ).unix();
//       return (
//         indexSchedule !== indexItem &&
//         appointmentStart < scheduleEnd &&
//         appointmentEnd > scheduleStart
//       );
//     });
//     if (scheduleResp) {
//       newList[index].error = true;
//       setAvailability([...newList]);
//       setDisabledButton(true);
//       return true;
//     }
//     newList[index].error = false;
//     setDisabledButton(false);
//     setAvailability([...newList]);
//     return false;
//   };

//   const handleAddSchedule = (index: number) => () => {
//     const newAvailabilityItem = [...availability];
//     newAvailabilityItem[index].schedule.push({
//       start: { hour: null, minute: null },
//       end: { hour: null, minute: null },
//     });
//     setDisabledButton(true);
//     setAvailability([...newAvailabilityItem]);
//   };

//   const handleDeleteSchedule = (index: number, indexSchedule: number) => () => {
//     const newList = [...availability];
//     const schedule =
//       newList[index].schedule[newList[index].schedule.length - 1];
//     if (newList[index].schedule.length <= 1) return;
//     newList[index].schedule.splice(indexSchedule, 1);
//     setAvailability([...newList]);
//     for (let i = 0; i < newList[index].schedule.length; i++) {
//       if (!validateTime(schedule, index, i)) {
//         break;
//       }
//     }
//   };

//   const handleAppointmentType = (event: ChangeEvent<HTMLInputElement>) => {
//     const {
//       target: { value },
//     } = event;
//     if (value === "Telephonic") {
//       setTypeAppointment(AppointmentTypes.Telephonic);
//     } else {
//       setTypeAppointment(AppointmentTypes.InPerson);
//     }
//   };

//   const handleSubmit = async () => {
//     editAvailableAppointment.mutate();
//     setDisabledButton(true);
//   };

//   const handleAssignValue = (itemSchedule: SchedulePlanFront) => {
//     let date: RangeValue<Dayjs> = [null, null];
//     if (
//       itemSchedule.start.hour === null ||
//       itemSchedule.start.minute === null ||
//       itemSchedule.end.hour === null ||
//       itemSchedule.end.minute === null
//     )
//       return date;
//     date = [
//       dayjs(`${itemSchedule.start.hour}:${itemSchedule.start.minute}`, "HH:mm"),
//       dayjs(`${itemSchedule.end.hour}:${itemSchedule.end.minute}`, "HH:mm"),
//     ];
//     return date;
//   };

//   return (
//     <>
//       <div className="availability">
//         <Typography fontSize="1.25rem" paddingBottom="2.5rem">
//           Here's where you can set your appointment type and scheduling
//           availability. You can edit and create different windows, and schedule
//           patients, in your time.
//         </Typography>
//         <Typography fontSize="1.5rem" paddingBottom="0.25rem" bold>
//           Appointment type
//         </Typography>
//         <Typography fontSize="1.25rem" paddingBottom="1.5rem">
//           Select the type of appointments you are available for
//         </Typography>
//       </div>
//       <div className="container_type-appointment">
//         <>
//           <Checkbox
//             label="Telephone Appointment"
//             labelPosition="right"
//             classNameLabel="checkbox__label"
//             classNameCheckbox="checkbox__day"
//             value={AppointmentTypes.Telephonic}
//             onChange={handleAppointmentType}
//             checked={typeAppointment === AppointmentTypes.Telephonic}
//             name="typeAppointment"
//           ></Checkbox>
//         </>
//         <>
//           <Checkbox
//             label="In-Person Appointment"
//             labelPosition="right"
//             classNameLabel="checkbox__label"
//             classNameCheckbox="checkbox__day"
//             value={AppointmentTypes.InPerson}
//             name="typeAppointment"
//             onChange={handleAppointmentType}
//             checked={typeAppointment === AppointmentTypes.InPerson}
//           ></Checkbox>
//         </>
//       </div>
//       <Typography fontSize="1.5rem" bold paddingBottom="2.98rem">
//         Your working hours
//       </Typography>
//       {availability.map((item, index) => {
//         return (
//           <div className="pb-40" key={index}>
//             <div className="main_container">
//               <div className="availability__day">
//                 <div className="availability__day--subtitle">
//                   <Checkbox
//                     label={item.day}
//                     labelPosition="right"
//                     classNameLabel="checkbox__label"
//                     classNameCheckbox="checkbox__day"
//                     value={item.available.toString()}
//                     checked={item.available}
//                     onChange={handleCheck(index, !item.available)}
//                   ></Checkbox>
//                 </div>
//                 <div className="time_Schedule">
//                   {item.available &&
//                     item.schedule.map((itemSchedule, indexSchedule) => {
//                       return (
//                         <div className="flex" key={indexSchedule}>
//                           <TimePicker.RangePicker
//                             format={"h:mm A"}
//                             use12Hours
//                             allowClear
//                             value={handleAssignValue(itemSchedule)}
//                             minuteStep={15}
//                             className={`inpSchedule ${
//                               item.error ? "borderError" : ""
//                             }`}
//                             onChange={handleUpdateTime(index, indexSchedule)}
//                           ></TimePicker.RangePicker>
//                           <Button
//                             type="button"
//                             className="trashButton"
//                             onClick={handleDeleteSchedule(index, indexSchedule)}
//                           >
//                             <img
//                               className="trashIcon"
//                               src={trashIcon}
//                               alt="Trash Icon"
//                             />
//                           </Button>
//                         </div>
//                       );
//                     })}
//                   {item.error && (
//                     <span>These times overlap with another window.</span>
//                   )}
//                   {!availability[index].available && (
//                     <div className="unavailable_text">Unavailable</div>
//                   )}
//                 </div>
//               </div>
//               <div className="add_time">
//                 {availability[index].available && (
//                   <div
//                     className="availability__day--add"
//                     onClick={handleAddSchedule(index)}
//                   >
//                     + Add another time
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         );
//       })}
//       {(editAvailableAppointment.isError ||
//         editAvailableAppointment.isSuccess) && (
//         <Modal isOpen={isOpen} toggle={toggle} width="80">
//           {editAvailableAppointment.isError && (
//             <ResponseStatus
//               onClick={toggle}
//               status={ResponseStatuses.error}
//               title="Appointment not saved"
//               subtitle="There was an error saving your appointment availability. Please try again."
//               buttonText="Okay got it"
//               showIcon={false}
//             />
//           )}
//           {editAvailableAppointment.isSuccess && (
//             <ResponseStatus
//               onClick={toggle}
//               status={ResponseStatuses.success}
//               title="Appointment availability saved"
//               subtitle="Your appointment availability has been saved."
//               buttonText="Okay got it"
//               showIcon={false}
//             />
//           )}
//         </Modal>
//       )}
//       <Button
//         disabled={disabledButton}
//         className="saveButton"
//         onClick={handleSubmit}
//       >
//         Save
//       </Button>
//     </>
//   );
// };
// export default Availability;

const Availability = () => {
  return null;
};
export default Availability;
