import Widget from "../../library/components/Widget";
import cn from "clsx";
import "./index.css";
import { useQuery } from "react-query";
import api from "../../api/medl";
import { getRoundedNumber } from "../../utils/numbers";
import Feature from "../Feature/Feature";
import { Features } from "../../context/features";

interface DashboardWidgetsProps {
  className?: string;
}
const DashboardWidgets = ({ className }: DashboardWidgetsProps) => {
  const newPatients = useQuery("new-patients-report", () =>
    api.doctor.newPatientsReport()
  );
  const newPrescriptions = useQuery("new-prescriptions-report", () =>
    api.doctor.newPrescriptionsReport()
  );

  return (
    <section className={cn(className, "dashboard-widgets")}>
      {newPatients.isSuccess && (
        <Widget
          title="New patients"
          body={newPatients.data.result.count.toString()}
          percent={getRoundedNumber(newPatients.data.result.percentage) + "%"}
        />
      )}

      {newPrescriptions.isSuccess && (
        <Widget
          title="Issued prescriptions"
          body={newPrescriptions.data.result.count.toString()}
          percent={
            getRoundedNumber(newPrescriptions.data.result.percentage) + "%"
          }
        />
      )}

      <Feature
        name={Features.pendingRequests}
        on={<Widget title="Pending requests" body="12" percent="+10.3%" />}
      />
    </section>
  );
};

export default DashboardWidgets;
