// import FullCalendar from "@fullcalendar/react"; // must go before plugins
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import "./index.css";
// import dayjs from "dayjs";
// import { useCallback, useRef, useState } from "react";
// import { EventClickArg, EventContentArg } from "@fullcalendar/core";
// import Typography from "../../library/components/Typography";
// import ScheduleItem from "../ScheduleItem/ScheduleItem";
// import MedlLink from "../../library/components/MedlLink";
// import useModal from "../../hooks/useModal";
// import Modal from "../../library/components/Modal";
// import { Content } from "../ScheduleDetails/ScheduleDetails";
// import ConsultSearch from "../ConsultSearch";

// const ScheduledConsults = () => {
//   const ref = useRef<FullCalendar | null>(null);

//   const { isOpen, toggle } = useModal();
//   const [view, setView] = useState<"day" | "month">("day");

//   /** update state when view is switched */
//   const handleDatesSet = (arg: any) => {
//     if (arg.view.type === "timeGridDay") {
//       setView("day");
//     }
//     if (arg.view.type === "dayGridMonth") {
//       setView("month");
//     }
//   };

//   /** switch to day view on event click */
//   const handleEventClick = (info: EventClickArg) => {
//     if (view === "day") return;

//     if (ref.current) {
//       ref.current
//         .getApi()
//         .changeView("timeGridDay", info.event.start ?? undefined);
//     }
//   };

//   const getEventClassNames = (arg: EventContentArg) => {
//     if (arg.view.type === "timeGridDay") {
//       return "scheduled-consults__event--day";
//     }
//     return "";
//   };

//   const eventContent = useCallback(
//     (arg: EventContentArg) => {
//       if (arg.view.type === "timeGridDay") {
//         return (
//           <ScheduleItem
//             variant="today"
//             fontSize="1rem"
//             className="scheduled-consults__item"
//             details={
//               <MedlLink to="#" onClick={toggle}>
//                 View Details
//               </MedlLink>
//             }
//           />
//         );
//       }

//       return (
//         <div className="scheduled-consults__event">
//           <Typography fontSize="0.75rem">
//             {dayjs(arg.event.start).format("h:mm A")} - {arg.event.title}
//           </Typography>
//         </div>
//       );
//     },
//     [toggle]
//   );

//   return (
//     <div className="scheduled-consults">
//       <ConsultSearch />

//       <FullCalendar
//         ref={ref}
//         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
//         initialView="dayGridMonth"
//         headerToolbar={{
//           left: "prev,next today",
//           center: "title",
//           right: "dayGridMonth,timeGridDay",
//         }}
//         dayMaxEvents={true}
//         eventContent={eventContent}
//         eventClassNames={getEventClassNames}
//         eventClick={handleEventClick}
//         datesSet={handleDatesSet}
//         events={[
//           {
//             title: "Example 1",
//             date: dayjs().date(27).toDate(),
//             start: dayjs().set("hour", 8).set("minute", 0).toDate(),
//             end: dayjs().set("hour", 9).set("minute", 0).toDate(),
//           },
//           {
//             title: "Example 2",
//             date: dayjs().set("date", 27).toDate(),
//             start: dayjs().set("hour", 10).set("minute", 0).toDate(),
//             end: dayjs().set("hour", 11).set("minute", 0).toDate(),
//           },
//           {
//             title: "Example 21",
//             date: dayjs().set("date", 27).toDate(),
//             start: dayjs().set("hour", 11).set("minute", 0).toDate(),
//             end: dayjs().set("hour", 12).set("minute", 0).toDate(),
//           },
//           {
//             title: "Example 22",
//             date: dayjs().set("date", 27).toDate(),
//             start: dayjs().set("hour", 12).set("minute", 0).toDate(),
//             end: dayjs().set("hour", 1).set("minute", 0).toDate(),
//           },
//           {
//             title: "Example 4",
//             date: dayjs().set("date", 10).toDate(),
//             start: dayjs()
//               .set("hour", 10)
//               .set("minute", 0)
//               .set("date", 10)
//               .toDate(),
//             end: dayjs()
//               .set("hour", 11)
//               .set("minute", 0)
//               .set("date", 10)
//               .toDate(),
//           },
//           {
//             title: "Example 5",
//             date: dayjs().set("date", 10).toDate(),
//             start: dayjs()
//               .set("hour", 12)
//               .set("minute", 0)
//               .set("date", 10)
//               .toDate(),
//             end: dayjs()
//               .set("hour", 14)
//               .set("minute", 0)
//               .set("date", 10)
//               .toDate(),
//           },
//         ]}
//       />

//       <Modal
//         isOpen={isOpen}
//         toggle={toggle}
//         className="scheduled-consults__modal"
//       >
//         <Content type="today" onClose={toggle} />
//       </Modal>
//     </div>
//   );
// };
// export default ScheduledConsults;

const ScheduledConsults = () => {
  return null;
};
export default ScheduledConsults;
