import { useContext } from "react";
import { FeatureContext, Features } from "../../context/features";
import Switch from "../../library/components/Switch";
import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";

const FeatureMenu = () => {
  const { features, setFeatures } = useContext(FeatureContext);

  const handleFeatureClick =
    (feature: Features) =>
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      let next = [...features];
      const { checked } = event.currentTarget;

      if (checked === true) next.push(feature);
      else next = next.filter((value) => value !== feature);
      setFeatures(next);
    };

  return (
    <div className="feature-menu">
      <Typography variant={TypographyVariants.h3}> Features: </Typography>
      <div className="feature-menu__option">
        <Typography variant={TypographyVariants.h5}>
          Pending Requests Widget
        </Typography>
        <Switch
          onClick={handleFeatureClick(Features.pendingRequests)}
          defaultChecked={features.includes(Features.pendingRequests)}
        />
      </div>
      <div className="feature-menu__option">
        <Typography variant={TypographyVariants.h5}>Test App Errors</Typography>
        <Switch
          onClick={handleFeatureClick(Features.error)}
          defaultChecked={features.includes(Features.error)}
        />
      </div>
      <div className="feature-menu__option">
        <Typography variant={TypographyVariants.h5}>Scheduling</Typography>
        <Switch
          onClick={handleFeatureClick(Features.scheduling)}
          defaultChecked={features.includes(Features.scheduling)}
        />
      </div>
    </div>
  );
};

export default FeatureMenu;
