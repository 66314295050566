import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import api from "../../api/medl";
import Box from "../../library/components/Box";
import Button, { ButtonVariants } from "../../library/components/Button";
import LoadingSpinner from "../../library/components/LoadingSpinner";
import MedlLogo from "../../library/components/MedlLogo";
import ResponseStatus, {
  ResponseStatuses,
} from "../../library/components/ResponseStatus";
import TextField from "../../library/components/TextField";
import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
import PhoneIcon from "../../assets/icons/phone.svg";
import MailIcon from "../../assets/icons/envelope.svg";

interface Form {
  password: string;
  confirmPassword: string;
  otp: string;
}

const ChangePassword = () => {
  const queryClient = useQueryClient();
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Form>();

  const {
    mutate: tokenMutate,
    isError: tokenError,
    isLoading: tokenLoading,
    isSuccess: tokenSuccess,
    reset: tokenReset,
  } = useMutation((value: string) => api.doctorVerifyEmail(value), {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
    },
  });
  const updatePassword = useMutation(
    ({ otp, password }: Form) => api.doctorResetPassword(otp, password),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
      },
    }
  );

  useEffect(() => {
    if (token === undefined) return;
    tokenMutate(token);
  }, [token, tokenMutate]);

  const arePasswordsEqual = () => {
    const result = getValues(["password", "confirmPassword"]);
    if (result[0].localeCompare(result[1]) === 0) return true;
    return false;
  };

  const onSubmit = (form: Form) => {
    updatePassword.mutate(form);
  };

  const handleNotificationToggle = () => {
    tokenReset();
    updatePassword.reset();
  };

  return (
    <div className="change-password">
      <div>
        <MedlLogo />
      </div>
      <div className="change-password__container">
        <Box className="change-password__paper">
          {tokenLoading === true && <LoadingSpinner />}

          {tokenSuccess === true &&
            updatePassword.isSuccess === false &&
            updatePassword.isError === false && (
              <>
                <Typography variant={TypographyVariants.h2}>
                  Change your password
                </Typography>
                <Typography>
                  Enter your new password, anf repeat it to confirm. Use the OTP
                  sent to your phone to verify your identity and change your
                  password.
                </Typography>
                <div className="change-password__spacer" />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    {...register("password", {
                      required: "The password is required",
                    })}
                    placeholder="New password"
                    errorMessage={errors?.password?.message}
                    error={!!errors.password}
                    type="password"
                  />
                  <TextField
                    {...register("confirmPassword", {
                      required: "The confirm password is required",
                      validate: {
                        passwordsNotEqual: () =>
                          arePasswordsEqual() || "Passwords do not match",
                      },
                    })}
                    placeholder="Confirm password"
                    errorMessage={errors?.confirmPassword?.message}
                    error={!!errors.confirmPassword}
                    type="password"
                  />
                  <TextField
                    {...register("otp", {
                      required: "Please enter the one time password",
                    })}
                    placeholder="OTP"
                    errorMessage={errors?.otp?.message}
                    error={!!errors.otp}
                    type="password"
                  />
                  <div className="change-password__spacer" />
                  <Button
                    variant={ButtonVariants.contained}
                    type="submit"
                    disabled={updatePassword.isLoading}
                  >
                    Change password
                  </Button>
                  <Button>Cancel</Button>
                </form>
              </>
            )}

          {updatePassword.isSuccess === true && (
            <ResponseStatus
              status={ResponseStatuses.success}
              onClick={handleNotificationToggle}
              title="Password updated!"
              subtitle="You can now log in with your updated password."
              button={
                <Link to={"/"} className="change-password__button">
                  <Button variant={ButtonVariants.contained}>
                    Go to log in
                  </Button>
                </Link>
              }
            />
          )}

          {(updatePassword.isError === true || tokenError === true) && (
            <ResponseStatus
              status={ResponseStatuses.error}
              onClick={handleNotificationToggle}
              subtitle="We were unable to verify your account at this time. Please try again or contact Medl support for assistance"
              button={
                <Link to={"/"} className="change-password__button">
                  <Button variant={ButtonVariants.contained}>Retry</Button>
                </Link>
              }
            >
              <div className="contact">
                <img src={MailIcon} className="contact-icon" alt="Email Icon" />
                <Typography>Email us: </Typography>
                <Typography>hello@medl.co</Typography>

                <img
                  src={PhoneIcon}
                  className="contact-icon"
                  alt="Phone Icon"
                />
                <Typography>Contact us: </Typography>
                <div>
                  <Typography>+1 (868) 610 6335</Typography>
                  <Typography>+1 (868) 610 3587</Typography>
                </div>
              </div>
            </ResponseStatus>
          )}
        </Box>
      </div>
    </div>
  );
};

export default ChangePassword;
