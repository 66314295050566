import Box from "../../library/components/Box";
import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ResponseStatus, {
  ResponseStatuses,
} from "../../library/components/ResponseStatus";
import PrescriptionPreview from "../PrescriptionPreview";
import { ObjectId } from "bson";
import { formatDate } from "../../utils/dateFormat";
import api from "../../api/medl";
import "./index.css";
import { useState } from "react";
import { PrescriptionStatus } from "@medl-repo/sdk/dist/sdk/src/types/prescription.types";
import Button, { ButtonVariants } from "../../library/components/Button";
import cn from "clsx";
import Modal from "../../library/components/Modal";
import { Stack, Typography as MuiTypography } from "@mui/material";
import { blue } from "../../theme";
import TagsCard from "../TagsCard";
import { useMedlCirclePatients } from "../../hooks/usePatientApi";

export type PrescriptionRequest = {
  patientId: ObjectId;
};

type PrescriptionFilters =
  | PrescriptionStatus.Active
  | PrescriptionStatus.Pending
  | PrescriptionStatus.Expired;

const MedicalHistory = () => {
  const queryClient = useQueryClient();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { patientId } = useParams();

  const [status, setStatus] = useState<PrescriptionFilters>(
    PrescriptionStatus.Active
  );
  const [enableHistory, setEnableHistory] = useState(true);

  const patientDetails = useQuery(["patient", patientId], async () => {
    const patientDetailsResponse = await api.doctor.getPatientDetails(
      patientId || ""
    );
    return patientDetailsResponse;
  });
  const { data: history, remove: removeHistoryData } = useQuery(
    ["prescription-history", patientId],
    async () =>
      await api.doctor.getPatientPrescriptions(
        patientId || "",
        undefined,
        undefined,
        status
      ),
    {
      retry: (failureCount, error: Error) => {
        if (
          error.stack?.includes("Received Status Code: 403") ||
          failureCount > 2
        ) {
          return false;
        }

        return true;
      },
      enabled: enableHistory,
      onError: (error) => {
        if (error.stack?.includes("Received Status Code: 403")) {
          setIsNotificationOpen(true);
          removeHistoryData();
          setEnableHistory(false);
        }
      },
    }
  );

  const { getPatientMobile } = useMedlCirclePatients();

  const conditions = useQuery(["conditions", patientId], async () =>
    api.doctor.getConditionsList()
  );
  const allergies = useQuery(["allergies", patientId], async () =>
    api.doctor.getAllergiesList()
  );
  const considerations = useQuery(["considerations", patientId], async () =>
    api.doctor.getLifestylesList()
  );

  const saveConditions = useMutation(
    (list: string[]) =>
      api.doctor.updatePatientConditions({
        list,
        patientId: patientId || "",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["patient", patientId]);
      },
    }
  );
  const saveAllergies = useMutation(
    (list: string[]) =>
      api.doctor.updatePatientAllergies({
        list,
        patientId: patientId || "",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["patient", patientId]);
      },
    }
  );
  const saveConsiderations = useMutation(
    (list: string[]) =>
      api.doctor.updatePatientLifestyles({
        list,
        patientId: patientId || "",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["patient", patientId]);
      },
    }
  );

  const dob = formatDate(
    new Date(patientDetails?.data?.data?.dateOfBirth || 0)
  );

  const notificationToggle = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const handleFilterClick = (filter: PrescriptionFilters) => () => {
    removeHistoryData();
    setStatus(filter);
    setEnableHistory(true);
  };

  return (
    <div className="medical-history">
      <Stack spacing={4}>
        <Typography variant={TypographyVariants.h4}>Medical History</Typography>
        <Stack spacing={2}>
          <MuiTypography fontSize={"2rem"} color={blue[100]}>
            {patientDetails?.data?.data?.fullName}
          </MuiTypography>
          <Stack direction={"row"} spacing={5}>
            <Stack direction={"row"} spacing={1}>
              <MuiTypography fontWeight={700}>Date of Birth:</MuiTypography>
              <MuiTypography>{dob}</MuiTypography>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <MuiTypography fontWeight={700}>Phone Number:</MuiTypography>
              <MuiTypography>
                {getPatientMobile(
                  patientId!.toString(),
                  patientDetails?.data?.data?.mobile || ""
                )}
              </MuiTypography>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <MuiTypography fontWeight={700}>Email:</MuiTypography>
              <MuiTypography>{patientDetails?.data?.data?.email}</MuiTypography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction={"row"}
        spacing={6}
        mt={"1.5rem"}
        flexWrap={"wrap"}
        useFlexGap
        alignItems={"flex-start"}
      >
        <TagsCard
          title="Conditions & Disabilities"
          values={patientDetails.data?.data.conditions || []}
          id="conditions"
          options={conditions.data || []}
          onSave={saveConditions.mutate}
        />
        <TagsCard
          title="Drug & Food Allergies"
          values={patientDetails.data?.data.allergies || []}
          id="allergies"
          options={allergies.data || []}
          onSave={saveAllergies.mutate}
        />
        <TagsCard
          title="Considerations"
          values={patientDetails.data?.data.lifestyles || []}
          id="considerations"
          options={considerations.data || []}
          onSave={saveConsiderations.mutate}
        />
      </Stack>

      <Box className="history-list" elevation>
        <div className="history-list__header">
          <Typography bold>Drug History</Typography>
        </div>
        <div className="history__filter-container">
          <Button
            className={cn(
              "history__filter-button",
              "history__filter-button--start",
              {
                [`history__filter-button--pending`]:
                  status === PrescriptionStatus.Pending,
              }
            )}
            variant={
              status === PrescriptionStatus.Pending
                ? ButtonVariants.contained
                : undefined
            }
            onClick={handleFilterClick(PrescriptionStatus.Pending)}
          >
            Pending
          </Button>
          <Button
            className={cn("history__filter-button", {
              [`history__filter-button--active`]:
                status === PrescriptionStatus.Active,
            })}
            variant={
              status === PrescriptionStatus.Active
                ? ButtonVariants.contained
                : undefined
            }
            onClick={handleFilterClick(PrescriptionStatus.Active)}
          >
            Active
          </Button>
          <Button
            className={cn(
              "history__filter-button",
              "history__filter-button--end",
              {
                [`history__filter-button--expired`]:
                  status === PrescriptionStatus.Expired,
              }
            )}
            variant={
              status === PrescriptionStatus.Expired
                ? ButtonVariants.contained
                : undefined
            }
            onClick={handleFilterClick(PrescriptionStatus.Expired)}
          >
            Expired
          </Button>
        </div>
        {history &&
          history?.data?.map((prescription) => (
            <PrescriptionPreview
              className="history__card"
              key={`${prescription._id.toString()}`}
              prescription={prescription}
              status={false}
              date
              isDrugHistoryItem
            />
          ))}
      </Box>

      <Modal isOpen={isNotificationOpen} toggle={notificationToggle} width="50">
        <ResponseStatus
          onClick={notificationToggle}
          status={ResponseStatuses.error}
          subtitle="You do not have access to the patient's prescription history."
        />
      </Modal>
    </div>
  );
};

export default MedicalHistory;
