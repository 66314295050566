import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
import Box from "../../library/components/Box";
import { Outlet } from "react-router-dom";
import NavLink from "../../library/components/NavLink";

const Booking = () => {
  return (
    <div className="booking">
      <div className="booking__column">
        <Typography
          variant={TypographyVariants.h3}
          fontSize="1.875rem"
          className="booking__header"
        >
          Bookings
        </Typography>
        <Box className="booking__body">
          <div className="booking__header">
            <NavLink to="consults">
              {({ isActive }) => (
                <Typography
                  fontSize="1.875rem"
                  color={isActive ? TypographyColors.blue : undefined}
                  bold={isActive}
                >
                  Consults
                </Typography>
              )}
            </NavLink>

            <NavLink to="availability">
              {({ isActive }) => (
                <Typography
                  fontSize="1.875rem"
                  className="booking__header--space"
                  color={isActive ? TypographyColors.blue : undefined}
                  bold={isActive}
                >
                  Your availability
                </Typography>
              )}
            </NavLink>
          </div>

          <Outlet />
        </Box>
      </div>
    </div>
  );
};

export default Booking;
