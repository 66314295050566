import Button, { ButtonVariants } from "../../library/components/Button";
import TextField from "../../library/components/TextField";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import { useForm } from "react-hook-form";
import SignInPoster from "../SingInPoster";
import MedlLogo from "../../library/components/MedlLogo";
import isEmail from "validator/lib/isEmail";
import { Paths } from "../../routes";
import { useContext, useState } from "react";
import api from "../../api/medl";
import { UserContext } from "../../context/user";
import ForgotPasswordModal from "../ForgotPasswordModal";
import useModal from "../../hooks/useModal";
import { useMutation } from "react-query";
import Modal from "../../library/components/Modal";
import useLongPress from "../../hooks/useLongPress";
import FeatureMenu from "../FeatureMenu";

export interface SignInForm {
  email: string;
  password: string;
}

const SignIn = () => {
  const auth = useContext(UserContext);
  const navigate = useNavigate();
  const { isOpen, toggle } = useModal();
  const { isOpen: isHiddenMenuOpen, toggle: toggleHiddenMenu } = useModal();
  const longPressEvent = useLongPress({
    onLongPress: toggleHiddenMenu,
    shouldPreventDefault: true,
    delay: process.env.REACT_APP_ENV === "local" ? 300 : 3000,
  });

  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SignInForm>();

  const { mutate, isLoading } = useMutation(
    ({ email, password }: SignInForm) =>
      api.doctorLoginAndSave(email, password),
    {
      onSuccess: (result) => {
        setError(false);
        auth.setUser(result);
        if (result !== null) navigate(Paths.dashboard);
        else setError(true);
      },
      onError: (error) => {
        setError(true);

        //TODO: handle error
        console.error(error);
      },
    }
  );

  const handleChange =
    (name: keyof SignInForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      setValue(name, value);
    };

  const onSubmit = async (form: SignInForm) => {
    mutate(form);
  };

  return (
    <div className="sign-in">
      <div className="sign-in__right-container">
        <button className="sign-in__feature-button" {...longPressEvent} />
        <SignInPoster />
      </div>
      <div className="sign-in__left-container">
        <div className="sign-in__logo-container">
          <MedlLogo />
        </div>
        <div className="sign-in__left">
          <Typography
            variant={TypographyVariants.h2}
            className="sign-in__left-title"
          >
            Doctor Sign In
          </Typography>
          <form name="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("email", {
                required: "The email is required",
                validate: {
                  email: (value) => isEmail(value) || "*Incorrect Email Format",
                },
              })}
              className="sign-in__form-input"
              name="email"
              placeholder="Enter user email"
              onChange={handleChange("email")}
              errorMessage={errors?.email?.message}
              error={!!errors.email}
            />
            <TextField
              {...register("password", {
                required: "The password is required",
              })}
              className="sign-in__form-input"
              name="password"
              placeholder="Enter password"
              errorMessage={errors?.password?.message}
              error={!!errors.password}
              onChange={handleChange("password")}
              type="password"
            />
            {error && (
              <Typography
                className="form__error-msg"
                color={TypographyColors.error}
              >
                The Email or Password is Incorrect
              </Typography>
            )}
            <div>
              <Button
                type="button"
                variant={ButtonVariants.outlined}
                onClick={toggle}
              >
                Forgot Password
              </Button>
              <Button
                type="submit"
                variant={ButtonVariants.contained}
                disabled={isLoading}
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </div>

      <ForgotPasswordModal isOpen={isOpen} toggle={toggle} />

      <Modal isOpen={isHiddenMenuOpen} toggle={toggleHiddenMenu}>
        <FeatureMenu />
      </Modal>
    </div>
  );
};

export default SignIn;
