import Typography, { TypographyVariants } from "../Typography";
import Loading from "../../../assets/images/loading.gif";
import cn from "clsx";
import "./index.css";

interface LoadingSpinnerProps {
  className?: string;
  showTitle?: boolean;
  title?: string;
  subtitle?: string;
}

const LoadingSpinner = ({
  className,
  title,
  subtitle,
  showTitle = true,
}: LoadingSpinnerProps) => (
  <div className={cn(className, "loading")}>
    <img className={className} src={Loading} alt="Loading Spinner" />
    {showTitle && (
      <Typography variant={TypographyVariants.h2}>
        {title || "Loading"}
      </Typography>
    )}
    {subtitle && <Typography>{subtitle}</Typography>}
  </div>
);

export default LoadingSpinner;
