import cn from "clsx";
import "./index.css";

interface ChipProps extends React.LiHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
  color?: string;
}

const Chip: React.FunctionComponent<ChipProps> = ({
  children,
  className,
  color = "white",
  ...props
}: ChipProps) => (
  <div
    className={cn("chip", className, {
      [`chip--color-${color}`]: color,
    })}
    {...props}
  >
    {children}
  </div>
);

export default Chip;
