import Box from "../../library/components/Box";
import MedlLink from "../../library/components/MedlLink";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
// import ScheduleItem from "../ScheduleItem/ScheduleItem";
import cn from "clsx";
interface SchedulingWidgetProps {
  type: "today" | "pending";
  className?: string;
}
const SchedulingWidget = ({ type, className }: SchedulingWidgetProps) => {
  if (type === "today") return <TodayWidget className={className} />;
  if (type === "pending") return <PendingWidget className={className} />;
  return null;
};

export default SchedulingWidget;

interface TodayWidgetProps {
  className?: string;
}
const TodayWidget = ({ className }: TodayWidgetProps) => {
  return (
    <Box className={cn("scheduling-widget", className)} elevation>
      <div className="scheduling-widget__header">
        <Typography variant={TypographyVariants.h4} fontSize="1.5rem">
          Today's Appointments
        </Typography>
        <MedlLink to={"#"}>
          <Typography fontSize="1.5rem" color={TypographyColors.blue}>
            View all bookings
          </Typography>
        </MedlLink>
      </div>

      <div>
        {/* <ScheduleItem variant="today" />
        <ScheduleItem variant="today" />
        <ScheduleItem variant="today" /> */}
      </div>
    </Box>
  );
};

interface PendingWidgetProps {
  className?: string;
}
const PendingWidget = ({ className }: PendingWidgetProps) => {
  return (
    <Box className={cn("scheduling-widget", className)} elevation>
      <div className="scheduling-widget__header">
        <Typography variant={TypographyVariants.h4} fontSize="1.5rem">
          Pending Consultation Requests
        </Typography>
        <MedlLink to={"#"}>
          <Typography fontSize="1.5rem" color={TypographyColors.blue}>
            View all pending requests
          </Typography>
        </MedlLink>
      </div>

      <div>
        {/* <ScheduleItem variant="pending" />
        <ScheduleItem variant="pending" />
        <ScheduleItem variant="pending" />
        <ScheduleItem variant="pending" /> */}
      </div>
    </Box>
  );
};
