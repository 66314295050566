export const TERMS_OF_USE = [
  "Liability: The User expressly acknowledges and agrees that use of medl is at their sole risk and that the User has no recourse against medl in connection with the use of its platform or data. medl does not warrant the accuracy of any information, and any reliance on medl shall be at the User’s sole risk.",
  "The User acknowledges and agrees that medl is not intended as a substitute for professional medical advice, diagnosis or treatment. The User acknowledges that medl is not providing medical, diagnostic or any other advice through the services or by providing medical, diagnostic or any other advice through the services or by providing access to medl data.The User expressly acknowledges and agrees that the use of medl’s platform is at the User’s sole risk. medl and its affiliates do not warrant the accuracy of any data.",
  "medl shall not be liable for any loss or damage in connection with its platform or data. All express or implied conditions, warranties or undertakings, whether oral or in writing, including warranties as to accuracy, reliability, satisfactory quality or fitness for a particular purpose are expressly excluded.",
  "In no event shall medl be liable for any special, indirect, incidental, consequential or exemplary damages whatsoever in respect of any loss or damage which the User may incur or experience, except to the extent such loss or damage arises wholly or in part from medl’s negligence.",
  "Indemnification: Notwithstanding any other provision of this Agreement, the User shall:",
  "Indemnify and hold harmless medl and its directors, officers, servants and/or agents from and against any and all liabilities, claims, suits, actions or other proceedings (each, an “Action”) except to the extent such liabilities, claims, suits, actions or other proceedings arise wholly or in part from medl’s negligence; and",
  "be liable to medl and its directors, officers, servants and/or agents for any and all costs, damages and expenses, including without limitation reasonable legal fees and expenses, (collectively, the “Expenses”) in connection with any such Action which, in each case, may be brought or made against medl or such other persons, or which medl or such other persons may pay or incur as a result of or in connection with:",
  "any injury to persons (including injuries resulting in death) or loss of or damage to property of others which may be or is alleged to be caused by or suffered as a result of the User’s direct or indirect use of medl or any part thereof in contravention of this Agreement;",
  "any damages, injury or loss suffered by medl arising from or related to any breach or default by the User of any of the obligations of the User under this Agreement and including specifically, without limiting the generality of the foregoing, any direct, indirect, consequential and exemplary damages suffered by medl in this regard;",
  "any claims for privacy or security violations.",
  "Drugbank Disclaimer: Drugbank is a separate and distinct entity from medl. The DrugBank Data is intended for educational and scientific research purposes only and you expressly acknowledge and agree that use of the DrugBank Data is at your sole risk. There is no warranty on the accuracy of the DrugBank Data, and reliance on the DrugBank Data shall be at your sole risk. DrugBank Data is not intended as a substitute for professional medical advice, diagnosis or treatment.",
  "Confidentiality: The User represents that they shall keep all information shared with them confidential except as required for medical practice with the patient’s consent.",
  "Use of User’s Logo: medl may: (a) publicly announce the User’s use of medl in a press release or otherwise; and (b) reference the User (using the User’s name and logo) in published lists or directories of medl Users (including on medl’s website)",
  "User Responsibility for use: The User shall be responsible for all use of, and activities that occur under, its password(s), account, and other identification information and for any actions that take place through its access to medl’s web app, patient app, delivery service, customer service support, pharmacy and pharmacy support (whether conducted by the User or another).",
  "User Infringement of medl IP: The User confirms that they will not attempt to copy, infringe or reverse engineer medl’s and/or DrugBank Data’s IP in any way.",
  "Consent for Data Usage: The User consents to the use and dissemination of their data. User data will be managed strictly in accordance with medl’s data security best-practices which are compliant with applicable laws in the jurisdiction.",
  "User Representation: The User represents that they are a medical doctor or a dentist registered to practice in TT and prescribe medication.",
  "Electronic Signature Confirmation: The doctor confirms that this is their electronic signature for a prescription.",
];
