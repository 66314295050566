import Button, { ButtonVariants } from "../../library/components/Button";
import { useForm } from "react-hook-form";
import { ModalProps } from "../../library/components/Modal";
import TextField from "../../library/components/TextField";
import { TypographyVariants } from "../../library/components/Typography";
import "./index.css";
import isMobilePhone from "validator/lib/isMobilePhone";
import Typography from "../../library/components/Typography";
import close from "../../assets/icons/cross-button-icon.svg";
import { ResponseStatuses } from "../../library/components/ResponseStatus";
import { convertStringToDateUTC } from "../../utils/dateFormat";
import api from "../../api/medl";

interface AddNewPatientFields {
  firstName: string;
  lastName: string;
  dateOfBirthDay: string;
  dateOfBirthMonth: string;
  dateOfBirthYear: string;
  mobile: string;
  dateOfBirth: string;
}

interface AddNewPatientProps extends ModalProps {
  setStatus: (status: ResponseStatuses) => void;
  setIsNotificationOpen: (state: boolean) => void;
  setStatusMessage?: React.Dispatch<React.SetStateAction<string>>;
}

const AddNewPatient = ({
  toggle,
  setStatus,
  setIsNotificationOpen,
  setStatusMessage,
}: AddNewPatientProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddNewPatientFields>();

  const handleChange =
    (name: keyof AddNewPatientFields) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const {
        target: { value },
      } = event;
      setValue(name, value);
    };

  const onSubmit = async ({
    firstName,
    lastName,
    mobile,
    dateOfBirth,
  }: AddNewPatientFields) => {
    const date = convertStringToDateUTC(dateOfBirth);

    try {
      await api.doctor.registerPatient({
        firstName,
        lastName,
        mobile,
        dateOfBirth: date.getTime(),
      });
      setStatus(ResponseStatuses.success);
      setStatusMessage &&
        setStatusMessage("The patient was added successfully");
      toggle();
      setIsNotificationOpen(true);
    } catch {
      setStatus(ResponseStatuses.error);
      setStatusMessage &&
        setStatusMessage(
          "The new patient could not be added at this time. Please try again"
        );
      toggle();
      setIsNotificationOpen(true);
    }
  };

  return (
    <div className="add-patient">
      <img onClick={toggle} className="modal__close-icon" src={close} alt="" />
      <Typography className="modal__title" variant={TypographyVariants.h6} bold>
        Add A New Patient
      </Typography>
      <Typography variant={TypographyVariants.h6}>
        Enter patient information to create new patient
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("firstName", {
            required: "The first name is required",
          })}
          name="firstName"
          placeholder="First Name"
          errorMessage={errors?.firstName?.message}
          error={!!errors.firstName}
          onChange={handleChange("firstName")}
        />
        <TextField
          {...register("lastName", {
            required: "The last name is required",
          })}
          placeholder="Last Name"
          errorMessage={errors?.lastName?.message}
          error={!!errors.lastName}
        />

        <Typography>Date of Birth</Typography>
        <TextField
          {...register("dateOfBirth", {
            required: "The date of birth is required",
          })}
          placeholder="Date of birth"
          errorMessage={errors?.dateOfBirth?.message}
          error={!!errors.dateOfBirth}
          type="date"
          max={new Date().toISOString().split("T")[0]}
        />

        <TextField
          {...register("mobile", {
            required: "The mobile number is required",
            validate: {
              phone: (value) =>
                isMobilePhone(value, "any") || "The mobile number is invalid",
            },
          })}
          name="phone"
          placeholder="Phone number"
          onChange={handleChange("mobile")}
          errorMessage={errors?.mobile?.message}
          error={!!errors.mobile}
        />
        <Button type="submit" variant={ButtonVariants.contained}>
          Add Patient
        </Button>
      </form>
    </div>
  );
};

export default AddNewPatient;
