import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import Box, { BoxColors } from "../../library/components/Box";
import Button, { ButtonVariants } from "../../library/components/Button";
import PrescriptionPreview from "../PrescriptionPreview";
import { GoBackLink } from "../../library/components/GoBackLink";
import api from "../../api/medl";
import Checkbox from "../../library/components/Checkbox";
import { usePrescriptionMutations } from "../../hooks/usePrescriptionApi";
import { Paths } from "../../routes";
import { ObjectId } from "bson";

const PrescriptionSummaryPrint = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const { downloadPrescriptionItems, cancelPrescription } =
    usePrescriptionMutations();

  const { data, refetch } = useQuery(
    ["recentPrescriptions", patientId],
    async () => {
      const recentPrescriptions = await api.doctor.getRecentPrescriptions(
        patientId || ""
      );
      return recentPrescriptions;
    }
  );

  const handlePrescribeOnline = () => {
    return navigate(-1);
  };

  const patientDetails = useQuery(["patient", patientId], async () => {
    const patientDetails = await api.doctor.getPatientDetails(patientId || "");
    return patientDetails;
  });

  const handleChange =
    (prescriptionNumber: string, RxNumber: string) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { checked },
      } = event;
      await api.doctor.updatePrintFlags({
        prescriptionNumber,
        RxNumber,
        flagValue: checked,
      });
      refetch();
    };

  const handlePrint = () => {
    const rxNumbers: string[] = [];

    data?.data.forEach((prescription) => {
      if (prescription.printFlag) {
        rxNumbers.push(prescription.RxNumber);
      }
    });

    if (rxNumbers.length === 0) return;

    downloadPrescriptionItems.mutate({ rxNumbers });
  };

  const handleCancelPrescription = () => {
    cancelPrescription.mutate({
      prescriptionId: data?.data[0].prescriptionNumber || "",
      patientId: patientId || "",
    });
    navigate(Paths.patientsAndPrescriptions);
  };

  return (
    <div className="summary-print-container">
      <div>
        <div className="summary-print-container__header">
          <GoBackLink />
          <Typography variant={TypographyVariants.h3}>
            Print Prescription
          </Typography>
          <div className="prescription-summary-sections-subtitle-top">
            <Typography>
              Select the intended prescriptions to be printed for your patient
            </Typography>
          </div>
        </div>
        <Typography>Print Prescription</Typography>
        <Typography variant={TypographyVariants.h4} bold>
          {patientDetails?.data?.data?.fullName}
        </Typography>

        <Box color={BoxColors.grey} className="print__list">
          {data?.data
            ?.map((rp) => {
              return { ...rp, doctorId: new ObjectId(rp.doctorId._id) };
            })
            .map((prescription) => (
              <div
                className="print__list--item"
                key={`${prescription._id.toString()}`}
              >
                <PrescriptionPreview
                  prescription={prescription}
                  actions={false}
                />
                <Checkbox
                  onChange={handleChange(
                    prescription.prescriptionNumber,
                    prescription.RxNumber
                  )}
                  checked={prescription.printFlag}
                />
              </div>
            ))}
        </Box>
      </div>
      <div className="summary-print__right">
        <Typography
          variant={TypographyVariants.h3}
          className="prescription-summary-sections-title"
        >
          Print Prescription
        </Typography>
        <div className="prescription-summary-sections-subtitle">
          <Typography>
            Select the intended prescriptions to be printed for your patient
          </Typography>
        </div>
        <div className="summary__button-group">
          <Button variant={ButtonVariants.contained} onClick={handlePrint}>
            Print Prescriptions
          </Button>
          <Button
            variant={ButtonVariants.outlined}
            onClick={handlePrescribeOnline}
          >
            Prescribe online instead
          </Button>
          <Button
            variant={ButtonVariants.redOutlined}
            onClick={handleCancelPrescription}
          >
            Cancel prescriptions
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionSummaryPrint;
