import { FullInventoryItem } from "@medl-repo/sdk/dist/sdk/src/types/inventory.types";
import { useQuery } from "react-query";
import api from "../api/medl";
import { AutocompleteOption } from "../library/components/Autocomplete/Autocomplete";
import { SelectOption } from "../library/components/Select/Select";

export const useInventoryApi = (drugName: string, prescribableName: string) => {
  const { data: drugs } = useQuery(
    ["drugs", drugName],
    () => api.doctor.drugSearch(drugName),
    { enabled: drugName.length > 2 }
  );

  const drugDetailOptions = (
    drugs: FullInventoryItem[] | undefined
  ): AutocompleteOption[] => {
    if (drugs === undefined) return [];
    return drugs.map((drug) => ({
      value: drug.medicalProperties?.activeIngredient ?? "",
      id: drug._id.toString(),
    }));
  };

  const drugDetailFilter = (drugs: AutocompleteOption[]) => {
    const uniqueDrugsMap = new Map<string, AutocompleteOption>();
    //remove duplicate drugs
    drugs.forEach((drug) =>
      uniqueDrugsMap.set(drug.value.toLowerCase().trim(), drug)
    );
    return Array.from(uniqueDrugsMap.values());
  };

  const prescribableOptions = (
    drugs: FullInventoryItem[] | undefined
  ): SelectOption[] => {
    if (drugs === undefined) return [];

    return drugs.map((drug) => ({
      value: drug.name ?? "",
      id: drug._id.toString(),
      other: drug,
    }));
  };

  const getSelectedProduct = () => {
    if (drugs === undefined) return;
    const drug = drugs.find((drug) => drug.name === prescribableName);

    return drug;
  };

  const getSkuDescription = (drug: FullInventoryItem | undefined) => {
    if (drug === undefined || drug === null) return "";
    if (drug.medicalProperties === undefined)
      throw new Error("No medical properties found for drug");
    if (drug.medicalProperties.divisible === true) return "";
    if (drug.medicalProperties.nonDivisibleProperties === undefined)
      throw new Error("No non-divisible properties found for drug");

    const { packageType, packageDescription } =
      drug.medicalProperties.nonDivisibleProperties;
    return `${packageDescription} ${packageType}`;
  };

  const selectedDrug = getSelectedProduct();
  const isDivisible =
    selectedDrug === undefined
      ? true
      : Boolean(selectedDrug.medicalProperties?.divisible);

  return {
    drugs,
    drugDetailOptions: drugDetailFilter(drugDetailOptions(drugs)),
    prescribableOptions: prescribableOptions(drugs),
    selectedDrug,
    isDivisible,
    getSkuDescription,
  };
};
