import {
  DurationType,
  FrequencyType,
} from "@medl-repo/sdk/dist/sdk/src/types/prescription.types";

export const DURATION_OPTIONS = [
  DurationType.Days,
  DurationType.Weeks,
  DurationType.Months,
];

export const FREQUENCY_OPTIONS = [
  FrequencyType.Daily,
  FrequencyType.Weekly,
  FrequencyType.Monthly,
];
