import { useContext } from "react";
import { Features } from "../../context/features";
import { UserContext } from "../../context/user";
import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import DashboardWidgets from "../DashboardWidgets";
import Feature from "../Feature";
import NotificationWidget from "../NotificationWidget";
import "./index.css";
import CrashButton from "../CrashButton/CrashButton";
import SchedulingWidget from "../SchedulingWidget/SchedulingWidget";

const Dashboard = () => {
  const { user } = useContext(UserContext);

  if (user === null) return null;

  return (
    <div className="dashboard">
      <div className="dashboard__title dashboard--spacer">
        <Typography
          className="dashboard__title-label"
          variant={TypographyVariants.h3}
        >
          Welcome Dr. {user.fullName}
        </Typography>
        <Typography
          className="dashboard__title-label"
          variant={TypographyVariants.h4}
        >
          Here's your Dashboard
        </Typography>
      </div>

      <DashboardWidgets className="dashboard--spacer" />

      <Feature
        name={Features.scheduling}
        on={<SchedulingWidget type="today" className="dashboard--spacer" />}
      />

      <NotificationWidget className="dashboard--spacer" />

      <Feature
        name={Features.scheduling}
        on={<SchedulingWidget type="pending" className="dashboard--spacer" />}
      />

      <Feature
        name={Features.error}
        on={<CrashButton className="dashboard--spacer" />}
      />
    </div>
  );
};

export default Dashboard;
