import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import "./index.css";
import Modal, { ModalProps } from "../../library/components/Modal";
import Button, { ButtonVariants } from "../../library/components/Button";
import Checkbox from "../../library/components/Checkbox";
import { Paths } from "../../routes";
import { useState } from "react";
import ResponseStatus, {
  ResponseStatuses,
} from "../../library/components/ResponseStatus";
import { TERMS_OF_USE } from "./constants";
import { usePrescriptionMutations } from "../../hooks/usePrescriptionApi";

interface PrescriptionConfirmationFields {
  agreed: boolean;
}

interface PrescriptionConfirmationProps extends ModalProps {
  prescriptionNumber?: string;
}

const PrescriptionConfirmationModal = ({
  toggle,
  isOpen,
  prescriptionNumber,
}: PrescriptionConfirmationProps) => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const { confirmPrescription } = usePrescriptionMutations();

  const [error, setError] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PrescriptionConfirmationFields>();

  const notificationToggle = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const handleChange =
    (name: keyof PrescriptionConfirmationFields) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { checked },
      } = event;
      setValue(name, checked);
    };

  //TODO: convert to mutate
  const onSubmit = async () => {
    try {
      if (!!prescriptionNumber) {
        await confirmPrescription.mutateAsync({
          prescriptionNumber,
          patientId: patientId || "",
        });

        navigate(Paths.medicationPrescribed);
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Modal
      className="confirm__modal"
      isOpen={isOpen}
      toggle={toggle}
      hasCloseButton
    >
      <div className="modal--content">
        <Typography variant={TypographyVariants.h3}>
          Confirm Prescription
        </Typography>
        <Typography variant={TypographyVariants.h6}>Terms of Use</Typography>
        <div className="terms">
          {TERMS_OF_USE.map((term, index) => (
            <Typography key={`terms-${index}`} className="term">
              {term}
            </Typography>
          ))}
        </div>
        <form name="modal__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="terms--checkbox">
            <Checkbox
              {...register("agreed", {
                required: "Please indicate that you agree to the Terms of Use",
              })}
              label="I agree"
              onChange={handleChange("agreed")}
            />
            {!!errors.agreed && (
              <Typography>{errors?.agreed?.message}</Typography>
            )}
          </div>

          <Button type="submit" variant={ButtonVariants.contained}>
            Confirm And Sign Prescription
          </Button>
        </form>
      </div>
      {error && (
        <Modal
          isOpen={isNotificationOpen}
          toggle={notificationToggle}
          width="50"
        >
          <ResponseStatus
            status={ResponseStatuses.error}
            onClick={notificationToggle}
            subtitle="There was an issue. Try again later."
          />
        </Modal>
      )}
    </Modal>
  );
};

export default PrescriptionConfirmationModal;
