import {
  UseFormRegister,
  FieldErrorsImpl,
  Control,
  Controller,
} from "react-hook-form";
import { FREQUENCY_OPTIONS, DURATION_OPTIONS } from "../../constants/duration";
import Select from "../../library/components/Select";
import TextField from "../../library/components/TextField";
import { PrescriptionIssuanceForm } from "./PrescriptionIssuance";

export interface PartialPrescriptionFormProps {
  register: UseFormRegister<PrescriptionIssuanceForm>;
  errors: Partial<FieldErrorsImpl<PrescriptionIssuanceForm>>;
  control?: Control<PrescriptionIssuanceForm, any>;
  skuDescription?: string;
}
const DivisibleForm = ({
  register,
  errors,
  control,
}: PartialPrescriptionFormProps) => {
  return (
    <>
      <TextField
        placeholder="Enter Dosage here"
        {...register("dosage", {
          required: "The dosage is required",
        })}
        errorMessage={errors.dosage?.message}
        error={!!errors.dosage}
        type="number"
      />

      <div className="issue__fields--inline">
        <div className="issue__fields--half-width issue__fields--left">
          <TextField
            placeholder="Frequency"
            {...register("frequency", {
              required: "The frequency is required",
            })}
            errorMessage={errors.frequency?.message}
            error={!!errors.frequency}
            type="number"
          />
        </div>
        <div className="issue__fields--half-width">
          <Controller
            control={control}
            name={"frequencyType"}
            rules={{ required: "The frequency type is required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={FREQUENCY_OPTIONS}
                errorMessage={errors.frequencyType?.message}
                error={!!errors.frequencyType}
              />
            )}
          />
        </div>
      </div>
      <div className="issue__fields--inline">
        <div className="issue__fields--half-width issue__fields--left">
          <TextField
            placeholder="Duration"
            {...register("duration", {
              required: "The duration is required",
            })}
            errorMessage={errors.duration?.message}
            error={!!errors.duration}
            type="number"
          />
        </div>
        <div className="issue__fields--half-width">
          <Controller
            control={control}
            name={"durationType"}
            rules={{ required: "The duration type is required" }}
            render={({ field }) => (
              <Select
                options={DURATION_OPTIONS}
                {...field}
                errorMessage={errors.durationType?.message}
                error={!!errors.durationType}
                placeholder="Month"
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default DivisibleForm;
