import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const formatDate = (date: Date, format = "MM/DD/YYYY") =>
  dayjs.utc(date).format(format);

export const convertStringToDateUTC = (date: string) =>
  dayjs.utc(date).toDate();

const currentYear = new Date().getFullYear();

export const range = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  ).map((item) => ({ id: item.toString(), label: item.toString() }));

export const yearsOptions = range(1910, currentYear, 1).map((item) => {
  return { id: item.id, value: item.label };
});
export const monthsOptions = range(1, 12, 1).map((item) => {
  return { id: item.id, value: item.label };
});
export const daysOptions = range(1, 31, 1).map((item) => {
  return { id: item.id, value: item.label };
});

/**
 * will return the age of the person given their date of birth
 * @param dob date of birth
 * @returns the age of the person
 */
export const getAge = (dob: Date) => {
  return dayjs.utc().diff(dob, "year");
};
