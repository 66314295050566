import Box, { BoxColors } from "../../library/components/Box";
import { Link, useParams } from "react-router-dom";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import PrescriptionPreview from "../PrescriptionPreview";
import { ObjectId } from "bson";
import MedicalHistory from "../../assets/icons/medical-history-icon.svg";
import RedirectLink from "../RedirectLink";
import "./index.css";
import MedlLink, { LinkColors } from "../../library/components/MedlLink";
import ConfirmationModal from "../../library/components/ConfirmationModal";
import useModal from "../../hooks/useModal";
import { Prescription } from "@medl-repo/sdk/dist/sdk/src/types/prescription.types";
import { usePrescriptionMutations } from "../../hooks/usePrescriptionApi";
import { useState } from "react";
import Button, { ButtonVariants } from "../../library/components/Button";
import PatientPrescriptionsModal from "./PatientPrescriptionsModal";

interface PrescriptionAddedItemsProps {
  recentPrescriptions: Prescription[] | undefined;
  summaryPrescriptions: Prescription[] | undefined;
}
const PrescriptionAddedItems = ({
  recentPrescriptions,
  summaryPrescriptions,
}: PrescriptionAddedItemsProps) => {
  const { patientId } = useParams();
  const confirmModal = useModal();
  const historyModal = useModal();

  const [prescription, setPrescription] = useState<Prescription | null>(null);

  const { deleteRxItem } = usePrescriptionMutations();

  const handleDelete = () => {
    if (prescription === null || patientId === undefined) return;
    deleteRxItem.mutate({
      patientId: new ObjectId(patientId),
      prescriptionId: prescription._id.toString(),
      prescriptionNumber: prescription.prescriptionNumber,
      rxNumber: prescription.RxNumber,
    });
    confirmModal.toggle();
  };

  const handleDeleteLinkClick = (prescription: Prescription) => () => {
    setPrescription(prescription);
    confirmModal.toggle();
  };

  return (
    <div className="script-items__container">
      <Box color={BoxColors.grey} className="script-items">
        <Typography variant={TypographyVariants.h4}>
          Prescription Queue
        </Typography>
        {recentPrescriptions?.map((prescription) => (
          <PrescriptionPreview
            key={prescription._id.toString()}
            prescription={prescription}
            condensed
            actionElements={
              <div className="preview--links">
                <MedlLink
                  color={LinkColors.blue}
                  to={""}
                  state={{ prescription, action: "edit" }}
                >
                  <Typography
                    variant={TypographyVariants.body2}
                    color={TypographyColors.blue}
                  >
                    Edit RX
                  </Typography>
                </MedlLink>
                <MedlLink
                  color={LinkColors.red}
                  onClick={handleDeleteLinkClick(prescription)}
                  to={""}
                >
                  <Typography
                    variant={TypographyVariants.body2}
                    color={TypographyColors.error}
                  >
                    Delete
                  </Typography>
                </MedlLink>
              </div>
            }
          />
        ))}

        <RedirectLink
          className="history__link"
          src={MedicalHistory}
          to={"#"}
          color={TypographyColors.pink}
          label="View active Rx and medical history"
          onClick={historyModal.toggle}
        />

        {recentPrescriptions && recentPrescriptions.length > 0 && (
          <Link to={`prescription-summary`} className="prescription__create">
            <Button variant={ButtonVariants.contained} color="success">
              Create Prescription
            </Button>
          </Link>
        )}

        <ConfirmationModal
          toggle={confirmModal.toggle}
          isOpen={confirmModal.isOpen}
          handleConfirm={handleDelete}
          subtitle="Are you sure you want to delete this Prescription?"
          primaryActionText="Confirm"
          title="Delete Prescription"
        />

        <PatientPrescriptionsModal
          isOpen={historyModal.isOpen}
          toggle={historyModal.toggle}
          summaryPrescriptions={summaryPrescriptions}
        />
      </Box>
    </div>
  );
};

export default PrescriptionAddedItems;
