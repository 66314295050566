import "./index.css";
import cn from "clsx";

const Switch = ({
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        className={cn(props.className, "switch__input")}
        {...props}
      />
      <span className="slider slider--round"></span>
    </label>
  );
};

export default Switch;
