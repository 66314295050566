import { useContext } from "react";
import { UserContext } from "../../context/user";
import Button, {
  ButtonSizes,
  ButtonVariants,
} from "../../library/components/Button";
import Typography, {
  TypographyAlignments,
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
import TextField from "../../library/components/TextField";
import { useMutation, useQueryClient } from "react-query";
import api from "../../api/medl";
import { useForm } from "react-hook-form";
import isMobilePhone from "validator/lib/isMobilePhone";
import CheckIcon from "../../assets/icons/check-solid.svg";
import { useAccountApi } from "../../hooks/useAccountApi";

interface AccountForm {
  mobile: string;
  address: string;
}

const Account = () => {
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { forgetPassword } = useAccountApi();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
  } = useForm<AccountForm>();

  const { mutate, isLoading, isSuccess, reset } = useMutation(
    ({ mobile, address }: AccountForm) =>
      api.doctor.updateAccount(mobile, address),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
        resetForm();
        setTimeout(() => {
          reset();
          forgetPassword.reset();
        }, 1200);
      },
    }
  );

  const handleChangePassword = () => {
    if (user === null) throw new Error("user not logged in");
    forgetPassword.mutate(user.email);
  };

  const onSubmit = (form: AccountForm) => {
    mutate(form);
  };

  if (user === null) return null;
  return (
    <div className="account">
      <div className="account__frame">
        <Typography
          variant={TypographyVariants.h2}
          align={TypographyAlignments.left}
        >
          Edit Profile
        </Typography>
        <Typography
          align={TypographyAlignments.left}
          lineHeight="2rem"
          paddingTop="1rem"
        >
          You can use this page to edit your saved information. If you have a
          question about changing your listed name or office, please get in
          touch with us.
        </Typography>

        <form className="account__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="account__form-section">
            <Typography
              variant={TypographyVariants.h3}
              align={TypographyAlignments.left}
            >
              Personal Information
            </Typography>
            <TextField defaultValue={user.email} disabled />
            <TextField
              defaultValue={user.mobile}
              placeholder="18681234567"
              {...register("mobile", {
                required: "The mobile number is required",
                validate: (value) =>
                  isMobilePhone(value) || "The mobile number is invalid",
              })}
              errorMessage={errors.mobile?.message}
              error={!!errors.mobile}
              autoComplete="off"
            />
          </div>
          <div className="account__form-section">
            <Typography
              variant={TypographyVariants.h3}
              align={TypographyAlignments.left}
            >
              Location
            </Typography>
            <TextField
              defaultValue={user.address}
              placeholder="Enter full address"
              {...register("address", {
                required: "The address is required",
              })}
              errorMessage={errors.address?.message}
              error={!!errors.address}
              autoComplete="off"
            />
          </div>

          <div className="account__form-section">
            <Typography
              variant={TypographyVariants.h3}
              align={TypographyAlignments.left}
            >
              Change Password
            </Typography>
            <Typography
              align={TypographyAlignments.left}
              variant={TypographyVariants.h6}
            >
              To change your medl password click the button below. We will send
              a Password Reset Pin to your registered mobile number
            </Typography>
            {forgetPassword.isSuccess === true ? (
              <div className="account__success account__success--change-pass">
                <Typography color={TypographyColors.blue}>
                  Reset email sent
                </Typography>
                <img src={CheckIcon} alt="Check Icon" />
              </div>
            ) : (
              <Button
                variant={ButtonVariants.outlined}
                size={ButtonSizes.small}
                className="account__change-password-button"
                type="button"
                onClick={handleChangePassword}
              >
                <Typography variant={TypographyVariants.h6}>
                  Change password
                </Typography>
              </Button>
            )}
          </div>

          {isSuccess === true ? (
            <div className="account__success">
              <Typography color={TypographyColors.blue}>
                Changes successfully saved
              </Typography>
              <img src={CheckIcon} alt="Check Icon" />
            </div>
          ) : (
            <Button
              type="submit"
              variant={ButtonVariants.contained}
              disabled={isLoading}
            >
              Save changes
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Account;
