import React, { useState } from "react";
import {
  Stack,
  Typography,
  Paper,
  Autocomplete,
  TextField,
  Box,
  IconButton,
  Chip,
  Alert,
  Button,
} from "@mui/material";
import { blue } from "../../theme";
import { EditRounded } from "@mui/icons-material";

interface Props {
  title: string;
  values: string[];
  id: string;
  options: string[];
  onSave: (list: string[]) => void;
}
const TagsCard = ({ title, values, id, options, onSave }: Props) => {
  const [edit, setEdit] = useState(false);
  const [list, setList] = useState<string[]>(values);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const handleSave = () => {
    onSave(list);
    toggleEdit();
  };

  const getChildren = () => {
    if (edit === true)
      return (
        <TagForm options={options} list={values} setList={setList} id={id} />
      );

    if (values.length === 0)
      return (
        <Alert severity="info">No {title.toLocaleLowerCase()} found</Alert>
      );

    return <TagDetails list={values} />;
  };

  return (
    <Paper variant="outlined" sx={{ p: "1.5rem", borderRadius: "1rem" }}>
      <Stack alignItems={"flex-start"} spacing={1}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">{title}</Typography>

          {edit ? (
            <Stack direction={"row"} spacing={1} ml={1}>
              <Button
                variant="contained"
                color="error"
                onClick={toggleEdit}
                size="small"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          ) : (
            <IconButton onClick={toggleEdit}>
              <EditRounded />
            </IconButton>
          )}
        </Box>
        <Box width={"100%"}>{getChildren()}</Box>
      </Stack>
    </Paper>
  );
};

export default TagsCard;

interface TagFormProps {
  options: string[];
  setList: (list: string[]) => void;
  list: string[];
  id: string;
}
const TagForm = ({ options, list, setList, id }: TagFormProps) => {
  const [value, setValue] = useState<string[]>(list);

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string[]
  ) => {
    if (newValue === null) return;

    setList(newValue);
    setValue(newValue);
  };
  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      multiple
      id={`${id}-select`}
      options={options}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} variant="standard" />}
      onSelect={(e) => console.log(e)}
    />
  );
};

interface TagDetailsProps {
  list: string[];
}
const TagDetails = ({ list }: TagDetailsProps) => {
  return (
    <Stack direction={"row"} spacing={1} flexWrap={"wrap"} useFlexGap>
      {list.map((value) => (
        <Chip key={value} label={value} sx={{ bgcolor: blue[30] }} />
      ))}
    </Stack>
  );
};
