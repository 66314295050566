import React from "react";
import cn from "clsx";

import "./index.css";

export enum ButtonSizes {
  small = "small",
  medium = "medium",
  large = "large",
}

export enum ButtonVariants {
  outlined = "outlined",
  contained = "contained",
  redOutlined = "red-outlined",
  link = "link",
}

type ButtonColor = "neutral" | "success";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  children?: React.ReactNode;
  className?: string;
  color?: ButtonColor;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  variant = ButtonVariants.outlined,
  size = ButtonSizes.medium,
  className = "",
  color,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={cn("button", className, {
        [`button--variant-${variant}`]: variant,
        [`button--size-${size}`]: size,
        [`button--color-${color}`]: color,
      })}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
