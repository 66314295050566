import React, { forwardRef } from "react";
import "./index.css";
import {
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
} from "@mantine/core";
import ExpandMoreIcon from "../../../assets/icons/expand_more.svg";
import Typography from "../Typography";

export type SelectOption = {
  /** unique identifier for option */
  id: string;

  /** the text displayed to the user for this option */
  value: string;

  /** other data for manipulation */
  other?: any;
};

export interface SelectProps
  extends Omit<MantineSelectProps, "data" | "onChange" | "error"> {
  options: SelectOption[] | string[];
  onChange: (event: any) => void;
  error?: boolean;
  errorMessage?: string;
  itemComponent?: React.FC<any>;
}
const Select = forwardRef<HTMLInputElement, SelectProps>(
  ({ options, placeholder, errorMessage, itemComponent, ...props }, ref) => {
    return (
      <>
        <MantineSelect
          className="select"
          {...props}
          ref={ref}
          variant={"unstyled"}
          data={options.map((item) => {
            if (typeof item === "string") return item;
            return { ...item, label: item.value };
          })}
          placeholder={placeholder}
          rightSection={
            <img
              src={ExpandMoreIcon}
              alt="Expand More Icon"
              className="select__expand-icon"
            />
          }
          itemComponent={itemComponent}
          onChange={(value) => {
            props.onChange({ target: { value: value } });
          }}
        />
        {props.error && (
          <Typography className="select__error-label">
            {errorMessage}
          </Typography>
        )}
      </>
    );
  }
);

export default Select;
