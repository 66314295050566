import { forwardRef } from "react";
import Typography, { TypographyColors } from "../Typography";
import cn from "clsx";
import "./index.css";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  classNameCheckbox?: string;
  errorMessage?: string;
  error?: boolean;
  checked?: boolean;
  labelPosition?: "left" | "right";
  classNameLabel?: string;
}
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      errorMessage,
      error,
      checked,
      className,
      classNameCheckbox = "checkbox__input",
      classNameLabel,
      labelPosition = "left",
      ...props
    },
    ref
  ) => {
    return (
      <div ref={ref} className={cn("checkbox", className)}>
        {label && labelPosition === "left" && (
          <Label {...{ label, labelPosition, classNameLabel }} />
        )}
        <input
          type="checkbox"
          className={classNameCheckbox}
          checked={checked}
          {...props}
        />
        {label && labelPosition === "right" && (
          <Label {...{ label, labelPosition }} />
        )}
        {error && (
          <Typography color={TypographyColors.error}>{errorMessage}</Typography>
        )}
      </div>
    );
  }
);

const Label = (props: {
  label: string;
  labelPosition: "left" | "right";
  classNameLabel?: string;
}) => {
  const className = cn("checkbox__label", {
    "checkbox__label--left": props.labelPosition === "left",
    "checkbox__label--right": props.labelPosition === "right",
    [props.classNameLabel || ""]: props.classNameLabel,
  });

  return (
    <label htmlFor={props.label} className={className}>
      {props.label}
    </label>
  );
};

export default Checkbox;
