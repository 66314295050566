import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import "./index.css";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import Box, { BoxColors } from "../../library/components/Box";
import Button, { ButtonVariants } from "../../library/components/Button";
import PrescriptionPreview from "../PrescriptionPreview";
import PrescriptionConfirmationModal from "../PrescriptionConfirmationModal";
import { GoBackLink } from "../../library/components/GoBackLink";
import useModal from "../../hooks/useModal";
import api from "../../api/medl";
import { Paths } from "../../routes";
import { usePrescriptionMutations } from "../../hooks/usePrescriptionApi";
import { ObjectId } from "bson";

const PrescriptionSummary = () => {
  const { isOpen, toggle } = useModal();
  const { patientId } = useParams();
  const { cancelPrescription } = usePrescriptionMutations();
  const navigate = useNavigate();

  const recentPrescriptions = useQuery(
    ["recentPrescriptions", patientId],
    async () => {
      const recentPrescriptions = await api.doctor.getRecentPrescriptions(
        patientId || ""
      );
      return recentPrescriptions;
    }
  );

  const patientDetails = useQuery(["patient", patientId], async () => {
    const patientDetails = await api.doctor.getPatientDetails(patientId || "");
    return patientDetails;
  });

  const handlePrint = () => {
    navigate(
      `${Paths.prescriptionIssuance}/${patientId}/prescription-summary/print`
    );
  };

  const handleCancelPrescription = () => {
    const prescriptionId = recentPrescriptions.data?.data[0].prescriptionNumber;
    if (prescriptionId === undefined)
      throw new Error("unable to get prescription id");
    if (patientId === undefined) throw new Error("unable to get patient id");

    cancelPrescription.mutate({ patientId, prescriptionId });
    navigate(Paths.patientsAndPrescriptions);
  };

  return (
    <div className="summary-container">
      <div>
        <div className="summary-container__header">
          <GoBackLink />
          <Typography variant={TypographyVariants.h3}>
            Review prescription
          </Typography>
          <div className="summary-sections-subtitle-top">
            <Typography>
              Please review the intended prescriptions to ensure that there are
              no errors before moving forward. This will ensure that patients
              are properly prescribed for their ailments.
            </Typography>
          </div>
        </div>
        <Typography>Prescription Summary</Typography>
        <Typography variant={TypographyVariants.h4} bold>
          {patientDetails?.data?.data?.fullName}
        </Typography>

        <Box color={BoxColors.grey} className="summary-list">
          {recentPrescriptions?.data?.data
            ?.map((rp) => {
              return { ...rp, doctorId: new ObjectId(rp.doctorId._id) };
            })
            .map((prescription) => (
              <PrescriptionPreview
                key={`${prescription._id.toString()}`}
                prescription={prescription}
                actions={false}
              />
            ))}
        </Box>
      </div>
      <div className="summary__right">
        <Typography
          variant={TypographyVariants.h3}
          className="summary-sections-title"
        >
          Review prescription
        </Typography>
        <div className="summary-sections-subtitle">
          <Typography>
            Please review the intended prescriptions to ensure that there are no
            errors before moving forward. This will ensure that patients are
            properly prescribed for their ailments.
          </Typography>
        </div>
        <div className="summary__button-group">
          <Button
            type="submit"
            variant={ButtonVariants.contained}
            onClick={toggle}
          >
            Proceed to confirmation
          </Button>
          <Button
            type="button"
            variant={ButtonVariants.outlined}
            onClick={handlePrint}
          >
            Print instead
          </Button>
          <Button
            type="button"
            variant={ButtonVariants.redOutlined}
            onClick={handleCancelPrescription}
          >
            Cancel prescriptions
          </Button>
        </div>
      </div>
      {isOpen && (
        <PrescriptionConfirmationModal
          isOpen={isOpen}
          toggle={toggle}
          prescriptionNumber={
            recentPrescriptions?.data?.data?.[0]?.prescriptionNumber
          }
        />
      )}
    </div>
  );
};

export default PrescriptionSummary;
