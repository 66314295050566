import Box from "../../../library/components/Box";
import Chip from "../../../library/components/Chip";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../../library/components/Typography";
import "./index.css";

interface WidgetProps {
  title: string;
  body: string;
  percent: string;
}
const Widget = ({ title, body, percent }: WidgetProps) => {
  return (
    <Box className="widget" elevation>
      <Typography variant={TypographyVariants.h4}>{title}</Typography>
      <div className="widget__body">
        <Typography variant={TypographyVariants.h2}>{body}</Typography>
        <Chip className="widget__chip">
          <Typography
            variant={TypographyVariants.body2}
            color={TypographyColors.white}
          >
            {percent}
          </Typography>
        </Chip>
      </div>
    </Box>
  );
};

export default Widget;
