import { useQuery } from "react-query";
import api from "../api/medl";
import { AutocompleteOption } from "../library/components/Autocomplete/Autocomplete";

export const useDiagnosisApi = (diagnosis: string) => {
  const { data: diagnosisOptions } = useQuery(
    ["diagnosis", diagnosis],
    () => api.doctor.searchDiagnosisCodes(diagnosis),
    { enabled: diagnosis.length > 2 }
  );

  /**
   * TODO: confirm that reducing the duplicates here aligns with the business logic
   */
  const filter = () => {
    if (diagnosisOptions === undefined) return [];
    const uniqueDiagnosisMap = new Map<string, AutocompleteOption>();
    //remove duplicate diagnosis
    diagnosisOptions.forEach((item) =>
      uniqueDiagnosisMap.set(item.description, {
        id: item.identifier,
        value: item.description,
      })
    );
    return Array.from(uniqueDiagnosisMap.values());
  };

  return { diagnosisOptions, diagnosisFilter: filter() };
};
