import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import { Paths } from "../../routes";
import "./index.css";

const MedicationPrescribed = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate(Paths.dashboard);
    }, 5000);
  }, [navigate]);

  return (
    <div className="prescribed">
      <Typography
        variant={TypographyVariants.h1}
        color={TypographyColors.white}
      >
        Medication Prescribed!
      </Typography>
      <Typography color={TypographyColors.white}>
        This will be sent to your patient through our Medl App, and can be
        monitored through the app
      </Typography>
      <Typography
        color={TypographyColors.white}
        className="prescribed--seconds"
      >
        Taking you back to dashboard in 5 seconds...
      </Typography>
    </div>
  );
};

export default MedicationPrescribed;
