import MedlLogoDark from "../../../assets/icons/medl-logo.svg";
import cn from "clsx";
import "./index.css";

export enum LogoVariants {
  light = "light",
  dark = "dark",
}

interface MedlLogoProps {
  variant?: LogoVariants;
  className?: string;
}

const MedlLogo = ({
  variant = LogoVariants.light,
  className,
}: MedlLogoProps) => (
  <img
    src={MedlLogoDark}
    alt="Medl Logo"
    className={cn(className, "medl-logo")}
  />
);

export default MedlLogo;
