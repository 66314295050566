import { createHashRouter } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import MedicalHistory from "../components/MedicalHistory";
import PrescriptionSummary from "../components/PrescriptionSummary";
import PrescriptionIssuance from "../components/PrescriptionIssuance";
import Page from "../components/Page";
import PatientsAndPrescriptions from "../components/PatientsAndPrescriptions";
import SignIn from "../components/SignIn";
import ProtectedRoute from "./ProtectedRoute";
import MedicationPrescribed from "../components/MedicationPrescribed";
import PrescriptionSummaryPrint from "../components/PrescriptionSummaryPrint";
import Help from "../components/Help";
import Account from "../components/Account";
import ChangePassword from "../components/ChangePassword";
import Notifications from "../components/Notifications";
import SignUp from "../components/SignUp";
import ErrorPage from "../components/ErrorPage";
import { wrapCreateBrowserRouter } from "@sentry/react";
import Booking from "../components/Bookings/Bookings";
import PendingConsults from "../components/PendingConsults/PendingConsults";
import Consults from "../components/Consults/Consults";
import ScheduledConsults from "../components/ScheduledConsults/ScheduledConsults";
import BookPatient from "../components/BookPatient/BookPatient";

import Availability from "../components/Availability/Availability";

export enum Paths {
  signIn = "/login",
  patientsAndPrescriptions = "/patients-and-prescriptions",
  dashboard = "/",
  notifications = "/notifications",
  help = "/help",
  medicalHistory = "/patients-and-prescriptions/medical-history",
  medicalHistoryId = "/patients-and-prescriptions/medical-history/:patientId",
  prescriptionSummary = "/patients-and-prescriptions/prescription-issuance/:patientId/prescription-summary",
  prescriptionSummaryPrint = "/patients-and-prescriptions/prescription-issuance/:patientId/prescription-summary/print",
  prescriptionIssuance = "/patients-and-prescriptions/prescription-issuance",
  prescriptionIssuanceId = "/patients-and-prescriptions/prescription-issuance/:patientId",
  medicationPrescribed = "/patients-and-prescriptions/prescription-issuance/medication-prescribed",
  account = "/account",
  changePassword = "/auth/reset_password/:token",
  signUp = "/auth/activate_account/:token",
  bookings = "/bookings",
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createHashRouter);

/**
 * TODO:
 * - add Sentry error boundary. currently adding it does not render the error page
 */

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Page />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            path: "*",
            element: <ErrorPage type="404" />,
          },
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: Paths.patientsAndPrescriptions,
            element: <PatientsAndPrescriptions />,
          },
          {
            path: Paths.medicalHistoryId,
            element: <MedicalHistory />,
          },
          {
            path: Paths.prescriptionSummary,
            element: <PrescriptionSummary />,
          },
          {
            path: Paths.prescriptionIssuanceId,
            element: <PrescriptionIssuance />,
          },
          {
            path: Paths.prescriptionSummaryPrint,
            element: <PrescriptionSummaryPrint />,
          },
          {
            path: Paths.help,
            element: <Help />,
          },
          {
            path: Paths.account,
            element: <Account />,
          },
          {
            path: Paths.notifications,
            element: <Notifications />,
          },
          {
            path: Paths.medicationPrescribed,
            element: <MedicationPrescribed />,
          },
          {
            path: Paths.bookings,
            element: <Booking />,
            children: [
              {
                path: "consults",
                element: <Consults />,
                children: [
                  {
                    index: true,
                    element: <PendingConsults />,
                  },
                  {
                    path: "scheduled",
                    element: <ScheduledConsults />,
                  },
                  {
                    path: "create",
                    element: <BookPatient />,
                  },
                ],
              },
              {
                path: "availability",
                element: <Availability />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: Paths.signIn,
    element: <SignIn />,
  },
  {
    path: Paths.changePassword,
    element: <ChangePassword />,
  },
  {
    path: Paths.signUp,
    element: <SignUp />,
  },
]);

export default router;
