import { ReactNode, MouseEvent } from "react";
import "./index.css";
import cn from "clsx";
import close from "../../../assets/icons/cross-button-icon.svg";

export type ModalWidth = "30" | "50" | "80" | "auto";

const modalWidths = {
  "30": "modal--width-30",
  "50": "modal--width-50",
  "80": "modal--width-80",
  auto: "modal--width-auto",
};

export interface ModalProps {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  hasCloseButton?: boolean;
  width?: ModalWidth;
}

export default function Modal({
  isOpen,
  toggle,
  children,
  className,
  hasCloseButton,
  width = "auto",
}: ModalProps) {
  const handleClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.stopPropagation();
  };

  if (isOpen === false) return null;
  return (
    <div className="modal__overlay" onClick={toggle}>
      <div
        onClick={handleClick}
        className={cn(className, "modal__box", modalWidths[width])}
      >
        {hasCloseButton && (
          <div className="modal__close">
            <img
              onClick={toggle}
              className="modal__close--icon"
              src={close}
              alt="Close"
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
