import { PaletteColor, createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    darkBlue: PaletteColor;
  }
  interface PaletteOptions {
    darkBlue: PaletteColor;
  }
}

export default createTheme({
  /** medl colours */
  palette: {
    darkBlue: {
      main: "#0a233f",
      light: "#3b4f65",
      dark: "#07182c",
      contrastText: "#f9f9f9",
    },
    primary: { main: "#4597c0" },
    secondary: { main: "#70c396" },
    success: { main: "#70c396" },
    error: { main: "#ef5477" },
    warning: { main: "#f69a6f" },
    grey: {
      500: "#e0e0e0", //secondary gray 30
      600: "#cacaca", //secondary gray 50
      700: "#b0b0b0", //secondary gray 75
      800: "#a0a0a0", //secondary gray 90
      900: "#959595", //secondary gray 100
    },
    common: {
      white: "#f9f9f9",
    },
  },
});

export const darkBlue = {
  100: "#0a233f",
  90: "#233953",
  75: "#546579",
  50: "#85919f",
  30: "#b6bdc6",
};

export const blue = {
  100: "#4597c0",
  90: "#58a2c7",
  75: "#7db7d3",
  50: "#a2cbe0",
  30: "#c8e0ed",
  20: "#daeaf2",
};

export const pink = {
  100: "#ef5477",
  90: "#f16685",
  75: "#f488a0",
  50: "#f7aabb",
  30: "#fbccd7",
};

export const green = {
  100: "#70c396",
  90: "#7fc9a1",
  75: "#9bd5b6",
  50: "#b8e1cb",
  30: "#d5ede0",
};

export const orange = {
  100: "#f69a6f",
  90: "#f7a57e",
  75: "#f9b99b",
  50: "#fbcdb7",
  30: "#fde1d4",
};

export const white = {
  100: "#f9f9f9",
  90: "#f9f9f9e6",
  75: "f9f9f9bf",
  50: "##f9f9f980",
  30: "#f9f9f94d",
};
