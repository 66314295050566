/**
 * this functions accepts a number and returns a string that represents the number with one decimal place.
 * if the number is an integer, it returns the number without the decimal place.
 * if the number is not a number, it returns 0.
 */
export const getRoundedNumber = (number: number, places = 1) => {
  if (number === null || number === undefined) return "0";

  if (isNaN(number)) {
    return "0";
  } else if (number === Math.floor(number)) {
    return number.toString();
  } else {
    return number.toFixed(places);
  }
};
