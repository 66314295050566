import {
  EditPrescription,
  PrescriptionOpts,
} from "@medl-repo/sdk/dist/sdk/src/types/doctor.types";
import { useQueryClient, useQuery, useMutation } from "react-query";
import api, { getServerDomain } from "../api/medl";
import { ObjectId } from "bson";

export const usePrescriptionQueries = (patientId: string | undefined) => {
  const recentPrescriptions = useQuery("recent-prescriptions", () =>
    api.doctor.getRecentPrescriptions(patientId)
  );

  const summaryPrescriptions = useQuery("summary-prescriptions", async () =>
    api.doctor.prescriptionSummary(new ObjectId(patientId))
  );

  return {
    recentPrescriptions,
    summaryPrescriptions,
  };
};

export const usePrescriptionMutations = () => {
  const queryClient = useQueryClient();

  const addRxItem = useMutation(
    (newRxItem: PrescriptionOpts) => api.doctor.addPrescriptionItem(newRxItem),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("recent-prescriptions");
      },
    }
  );

  const editRxItem = useMutation(
    (rxItem: EditPrescription) => api.doctor.editPrescription(rxItem),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("recent-prescriptions");
      },
    }
  );

  const deleteRxItem = useMutation(
    (params: {
      prescriptionNumber: string;
      patientId: ObjectId;
      rxNumber: string;
      prescriptionId: string;
    }) =>
      api.doctor.deletePrescriptionItem(
        params.prescriptionNumber,
        params.patientId,
        params.rxNumber,
        params.prescriptionId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("recent-prescriptions");
      },
    }
  );

  const downloadPrescription = useMutation(
    (params: { prescriptionNumber: string }) =>
      api.doctor.downloadPrescription(params.prescriptionNumber, false),
    {
      onSuccess(data) {
        if (!("result" in data))
          throw new Error(
            "could not get url from download prescription request"
          );

        window.location.href = getServerDomain() + data.result.pathToFile;
      },
    }
  );

  const downloadPrescriptionItems = useMutation(
    (params: { rxNumbers: string[] }) =>
      api.doctor.downloadRxNumbers(params.rxNumbers, false),
    {
      onSuccess(data) {
        if (!("result" in data))
          throw new Error(
            "could not get url from download prescription request"
          );

        window.location.href = getServerDomain() + data.result.pathToFile;
      },
    }
  );

  const cancelPrescription = useMutation(
    (params: { patientId: string; prescriptionId: string }) =>
      api.doctor.cancelPrescription(params.patientId, params.prescriptionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "recent-prescriptions",
          "summary-prescriptions",
        ]);
      },
    }
  );

  const confirmPrescription = useMutation(
    (params: { prescriptionNumber: string; patientId: string }) =>
      api.doctor.confirmPrescription(
        params.prescriptionNumber,
        new ObjectId(params.patientId)
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "recent-prescriptions",
          "summary-prescriptions",
        ]);
      },
    }
  );

  return {
    addRxItem,
    editRxItem,
    deleteRxItem,
    downloadPrescription,
    downloadPrescriptionItems,
    cancelPrescription,
    confirmPrescription,
  };
};
