import React from "react";
import searchIcon from "../../../assets/icons/search-icon.svg";
import cn from "clsx";
import "./index.css";

interface SearchTextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  rightNode?: JSX.Element;
}

const SearchTextField = ({
  className,
  rightNode,
  inputClassName,
  ...props
}: SearchTextFieldProps) => {
  const renderRightNode = () => {
    if (rightNode) return rightNode;

    return null;
  };

  return (
    <div className={cn("search", className)}>
      <img src={searchIcon} alt="Search Icon" />
      <input {...props} className={cn("search__input", inputClassName)} />
      {renderRightNode()}
    </div>
  );
};

export default SearchTextField;
