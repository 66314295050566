import Box from "../../library/components/Box";
import MedlLink from "../../library/components/MedlLink";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import { Paths } from "../../routes";
import Notification from "../Notification";
import "./index.css";
import { useQuery } from "react-query";
import api from "../../api/medl";

interface NotificationWidgetProps {
  className?: string;
}
export default function NotificationWidget({
  className,
}: NotificationWidgetProps) {
  const notifications = useQuery("notifications", () =>
    api.doctor.newNotificationsReport(10, 0)
  );

  const unreadNotifications = notifications.data?.result.filter(
    (notification) => notification.isRead === false
  );

  return (
    <Box className={className} elevation>
      <div className="notification-widget__header">
        <Typography variant={TypographyVariants.h4} fontSize="1.5rem">
          Notifications
        </Typography>
        <MedlLink to={Paths.notifications}>
          <Typography fontSize="1.5rem" color={TypographyColors.blue}>
            View All
          </Typography>
        </MedlLink>
      </div>

      <div>
        {unreadNotifications?.length === 0 ? (
          <Typography variant={TypographyVariants.h4} fontSize="1.25rem">
            No new notifications.
          </Typography>
        ) : null}
        {unreadNotifications?.map(
          (notification, index) =>
            index < 3 && (
              <Notification
                id={notification._id.toString()}
                key={`notification-${index}`}
                dateCreated={new Date(notification.createdAt * 1000)}
                title={notification.title}
                body={notification.body || ""}
                type={notification.type}
                isRead={notification.isRead}
              />
            )
        )}
      </div>
    </Box>
  );
}
