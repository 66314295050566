import Typography, {
  TypographyVariants,
} from "../../library/components/Typography";
import NotificationsBell from "../../assets/images/notifications-bell.gif";
import "./index.css";
import Box, { BoxColors } from "../../library/components/Box";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import api from "../../api/medl";
import { Notification as NotificationSdkType } from "@medl-repo/sdk/dist/sdk/src/types/doctor.types";
import Notification from "../Notification";
import { useState } from "react";

const PAGE_SIZE = 10;

const Notifications = () => {
  const [page, setPage] = useState(0);
  const [enabled, setEnabled] = useState(true);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      "notification-list",
      ({ pageParam = 0 }) =>
        api.doctor.newNotificationsReport(PAGE_SIZE, pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = page + 1;

          let sum = 0;
          allPages.forEach((page) => {
            sum += page.result.length;
          });

          const shouldUpdate = sum < lastPage.total;
          enabled !== shouldUpdate && setEnabled(shouldUpdate);

          return shouldUpdate ? nextPage : undefined;
        },
        enabled: enabled,
      }
    );

  const items: NotificationSdkType[] =
    data?.pages.reduce<NotificationSdkType[]>((acc, page) => {
      return [...acc, ...page.result];
    }, []) ?? [];

  const next = () => {
    if (hasNextPage) {
      setPage(page + 1);
      return fetchNextPage();
    }
  };

  return (
    <div className="notifications">
      <div>
        <Typography
          variant={TypographyVariants.h2}
          className="notifications__title"
        >
          Notifications
        </Typography>
        <Box
          color={BoxColors.grey}
          className="notifications__list"
          id="scrollableDiv"
        >
          <InfiniteScroll
            dataLength={items.length}
            next={next}
            hasMore={hasNextPage || false}
            loader={null}
            endMessage={<p>End of notifications</p>}
            scrollableTarget="scrollableDiv"
          >
            {items.map((item, index) => (
              <Notification
                id={item._id.toString()}
                body={item.body || ""}
                dateCreated={new Date(item.createdAt * 1000)}
                title={item.title}
                type={item.type}
                key={index}
                isRead={item.isRead}
              />
            ))}
            {isFetchingNextPage && <div>Loading more notifications...</div>}
          </InfiniteScroll>
        </Box>
      </div>
      <div className="notifications__right-side">
        <Typography variant={TypographyVariants.h4} bold>
          This is your Notification Centre
        </Typography>
        <img
          src={NotificationsBell}
          className="bell-image"
          alt="Notifications Bell"
        />
        <div className="notifications__sections-subtitle">
          <Typography variant={TypographyVariants.h4}>
            Get updates on your patients prescriptions, your outgoing orders and
            much more here
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
