import { MedlEnv, MedlApi } from "@medl-repo/sdk/dist/sdk/src";

/** Abstraction layer for the `MedlApi` sdk. Will expose a single instance for consumption */
class MedlApiService {
  private static api: MedlApi;

  private constructor() {}

  /**
   * get the `MedlApi` object.
   * will create a new instance if it does not exist.
   */
  public static getApi(): MedlApi {
    if (!MedlApiService.api) {
      let env = MedlEnv.local;
      switch (process.env.REACT_APP_ENV) {
        case "staging":
          env = MedlEnv.staging;
          break;
        case "sandbox":
          env = MedlEnv.sandbox;
          break;
        case "production":
          env = MedlEnv.production;
          break;
      }
      MedlApiService.api = new MedlApi(env);
    }

    return MedlApiService.api;
  }
}

const api = MedlApiService.getApi();

export default api;

export const getServerDomain = () => {
  let url = "http://localhost:3000";
  switch (process.env.REACT_APP_ENV) {
    case "staging":
      url = "https://staging.medl.me";
      break;
    case "production":
      url = "https://medl.me";
      break;
  }

  return url;
};
