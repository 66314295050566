import cn from "clsx";
import "./index.css";
import { NavLinkProps, NavLink as RouterNavLink } from "react-router-dom";
import { forwardRef } from "react";

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (
    { children, className, to, ...props }: NavLinkProps,

    ref
  ) => {
    return (
      <RouterNavLink
        ref={ref}
        to={to}
        className={cn("nav-link", className)}
        {...props}
      >
        {children}
      </RouterNavLink>
    );
  }
);

export default NavLink;
