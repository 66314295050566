import React, { forwardRef } from "react";
import Typography, { TypographyColors } from "../Typography";
import cn from "clsx";
import "./index.css";

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  errorMessage?: string;
  error?: boolean;
}

const TextArea = forwardRef<HTMLDivElement, TextAreaProps>(
  ({ className, errorMessage, error, ...props }, ref) => {
    return (
      <div ref={ref} className={cn("textarea__container", className)}>
        <textarea
          className={cn("textarea", {
            "textarea--border-error": error,
          })}
          {...props}
        />
        {error && (
          <Typography color={TypographyColors.error}>{errorMessage}</Typography>
        )}
      </div>
    );
  }
);

export default TextArea;
