import { DiagnosisCodeData } from "@medl-repo/sdk/dist/sdk/src/types/diagnosis.types";
import {
  FrequencyType,
  DurationType,
  Prescription,
} from "@medl-repo/sdk/dist/sdk/src/types/prescription.types";
import { useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/user";
import Autocomplete, {
  AutocompleteOption,
} from "../../library/components/Autocomplete/Autocomplete";
import Button, { ButtonVariants } from "../../library/components/Button";
import Checkbox from "../../library/components/Checkbox";
import { GoBackLink } from "../../library/components/GoBackLink";
import Select from "../../library/components/Select/Select";
import TextArea from "../../library/components/TextArea";
import TextField from "../../library/components/TextField";
import Typography, {
  TypographyColors,
  TypographyVariants,
} from "../../library/components/Typography";
import { ObjectId } from "bson";
import Modal from "../../library/components/Modal";
import PrescriptionAddedItems from "../PrescriptionAddedItems";
import { useWindowSize } from "../../hooks/useResponsiveness";
import useModal from "../../hooks/useModal";
import MedlLink from "../../library/components/MedlLink";
import "./index.css";
import DivisibleForm from "./DivisibleForm";
import NonDivisibleForm from "./NonDivisibleForm";
import { useInventoryApi } from "../../hooks/useInventoryApi";
import {
  usePrescriptionMutations,
  usePrescriptionQueries,
} from "../../hooks/usePrescriptionApi";
import { useDiagnosisApi } from "../../hooks/useDiagnosisApi";
import ConfirmationModal from "../../library/components/ConfirmationModal";
import { useQuery } from "react-query";
import api from "../../api/medl";
import PrescribableItem from "../PrescribableItem";
import MedlCareIcon from "../../assets/icons/medlcare-icon.svg";
import BDSIcon from "../../assets/icons/bds-icon.svg";
import { Country } from "@medl-repo/sdk/dist/sdk/src/types/address.types";
import { Box } from "@mui/material";

enum FrequencyMatrix {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
}
enum DurationMatrix {
  "Day(s)" = 0,
  "Week(s)" = 1,
  "Month(s)" = 2,
}

export interface PrescriptionIssuanceForm {
  drugName: string;
  prescribableName: string;
  isSubstitutable: boolean;
  diagnosis: string;

  dosage?: number | null;
  frequency?: number | null;
  frequencyType?: FrequencyType;
  duration?: number | null;
  durationType?: DurationType;

  units?: number | null;
  description: string;

  refill?: number | null;
  prn?: boolean;
  notes?: string;
}

const PrescriptionIssuance = () => {
  /** hooks */
  const { user } = useContext(UserContext);
  const { patientId } = useParams();
  const { state: routerState, pathname } = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { isOpen, toggle } = useModal();
  const { isOpen: isCancelEditOpen, toggle: toggleCancelEdit } = useModal();

  /** local state */
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDiagnosis, setSelectedDiagnosis] =
    useState<DiagnosisCodeData | null>(null);
  const {
    register,
    watch,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<PrescriptionIssuanceForm>();
  const { drugName, diagnosis, prescribableName } = watch();

  const resetForm = useCallback(() => {
    reset({
      drugName: "",
      prescribableName: "",
      isSubstitutable: false,
      diagnosis: "",

      dosage: null,
      frequency: null,
      frequencyType: FrequencyType.Daily,
      duration: null,
      durationType: DurationType.Months,

      units: null,
      description: "",

      refill: null,
      prn: false,
      notes: "",
    });
  }, [reset]);

  /** sever state */
  const {
    drugs,
    drugDetailOptions,
    prescribableOptions,
    selectedDrug,
    isDivisible,
    getSkuDescription,
  } = useInventoryApi(drugName || "", prescribableName || "");
  const { recentPrescriptions, summaryPrescriptions } =
    usePrescriptionQueries(patientId);
  const { addRxItem, editRxItem } = usePrescriptionMutations();
  const { diagnosisFilter, diagnosisOptions } = useDiagnosisApi(
    diagnosis || ""
  );
  const patientDetails = useQuery(["patient", patientId], () =>
    api.doctor.getPatientDetails(patientId || "")
  );

  /** effects */

  /**
   * here we check if the user navigates to this page with some state in the browser history.
   * if a prescription is defined, we can populate our form to begin editing.
   */
  useEffect(() => {
    if (routerState === null || undefined) return;

    const prescription = routerState["prescription"] as Prescription;
    if (prescription === undefined) return;

    if (routerState["action"] === "edit") setIsEditing(true);
    resetForm();
    setValue("drugName", prescription.typeName);
    setValue("prescribableName", prescription.prescName);
    setValue("diagnosis", prescription.diagnosisDescription);
    setSelectedDiagnosis({
      description: prescription.diagnosisDescription,
      identifier: prescription.diagnosisCode,
    });

    setValue("dosage", Number(prescription.quantity));
    prescription.frequency && setValue("frequency", prescription.frequency);
    prescription.frequencyType &&
      setValue("frequencyType", prescription.frequencyType);
    prescription.duration && setValue("duration", prescription.duration);
    prescription.schedule && setValue("durationType", prescription.schedule);

    setValue("units", Number(prescription.quantity));
    setValue("description", prescription.skuDescription || "");

    setValue("refill", prescription.refill);
    setValue("prn", prescription.prn);
    setValue("notes", prescription.notes);
  }, [routerState, resetForm, setValue]);

  /** local functions */

  const handleDrugSelect = (option: AutocompleteOption) => {
    resetForm();
    if (drugs === undefined) return undefined;
    const drug = drugs.find((drug) => drug._id.toString() === option.id);
    if (drug === undefined) return;
    setValue("drugName", drug.medicalProperties?.activeIngredient ?? "");
  };

  const handlePrescribableNameSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    //reset the form
    const drugId = event.target.value;
    const name = getValues("drugName");
    resetForm();
    setValue("drugName", name);

    //set prescribable name
    setValue("prescribableName", drugId);
  };

  const handleDiagnosisSelect = (option: AutocompleteOption) => {
    if (diagnosisOptions === undefined) return;
    const selected = diagnosisOptions.find(
      (value) => value.identifier === option.id
    );
    if (selected === undefined) return;
    setValue("diagnosis", option.value);
    setSelectedDiagnosis(selected);
  };

  const getQuantity = (
    dosage: number | null | undefined,
    units: number | null | undefined
  ) => {
    if (isDivisible === false) {
      if (units === undefined || units === null)
        throw new Error("quantity error: units is undefined or null");
      return units!.toString();
    }

    if (dosage === undefined || dosage === null)
      throw new Error("quantity error: dosage is undefined or null");
    return dosage!.toString();
  };

  const getPrescribedAmount = (
    dosage: number,
    frequency: number,
    frequencyType: FrequencyType,
    duration: number,
    durationType: DurationType,
    units: number | undefined | null
  ) => {
    const dosageMatrix = [
      [1, 7, 30],
      [0, 1, 4],
      [0, 0, 1],
    ];

    if (isDivisible === false) return units || 0;

    const i = FrequencyMatrix[frequencyType];
    const j = DurationMatrix[durationType];
    return Math.ceil(dosage * frequency * duration * dosageMatrix[i][j]);
  };

  const onSubmit = (form: PrescriptionIssuanceForm) => {
    if (selectedDiagnosis === null) return;
    if (selectedDrug === undefined) return;

    //grabbing the current prescription number currently being created.
    let prescriptionNumber: undefined | string;
    if (
      recentPrescriptions.data?.data &&
      recentPrescriptions.data.data.length > 0
    ) {
      prescriptionNumber = recentPrescriptions.data.data[0].prescriptionNumber;
    }

    addRxItem.mutate({
      diagnosisCode: selectedDiagnosis.identifier,
      diagnosisDescription: selectedDiagnosis.description,
      format: selectedDrug.medicalProperties?.strength,
      inventoryId: selectedDrug._id,
      ndcCode: selectedDrug.medicalProperties?.ndcCode,
      notes: form.notes || "",
      patientId: new ObjectId(patientId),
      prescName: form.prescribableName,
      prescribableName: selectedDrug.medicalProperties?.aliases,
      prescribedAmount: getPrescribedAmount(
        form.dosage!,
        form.frequency!,
        form.frequencyType!,
        form.duration!,
        form.durationType!,
        form.units
      ),
      printFlag: false,
      prn: form.prn || false,
      quantity: getQuantity(form.dosage, form.units),
      recommendedFormulation: selectedDrug.medicalProperties?.dosageForm,
      refill: form.refill || 0,
      route: selectedDrug.medicalProperties?.route,
      skuDescription: getSkuDescription(selectedDrug) || "",
      typeName: form.drugName,
      duration: form.duration!,
      frequency: form.frequency!,
      frequencyType: form.frequencyType,
      schedule: form.durationType,
      prescriptionNumber,
    });

    resetForm();
  };

  const handleClearForm = () => {
    resetForm();
    setSelectedDiagnosis(null);
  };

  const handleEdit = () => {
    if (selectedDiagnosis === null) return;
    const prescription = routerState.prescription as Prescription;
    if (prescription === undefined) return;
    const form = getValues();

    editRxItem.mutate({
      diagnosisCode: selectedDiagnosis.identifier,
      diagnosisDescription: selectedDiagnosis.description,
      format: selectedDrug?.medicalProperties?.strength || prescription.format,
      notes: form.notes || "",
      patientId: prescription.patientId.toString(),
      prescName: form.prescribableName,
      prescribableName:
        selectedDrug?.medicalProperties?.aliases ||
        prescription.prescribableName,
      prescribedAmount: getPrescribedAmount(
        form.dosage!,
        form.frequency!,
        form.frequencyType!,
        form.duration!,
        form.durationType!,
        form.units
      ),
      prescriptionNumber: prescription.prescriptionNumber,
      prn: form.prn || false,
      quantity: getQuantity(form.dosage, form.units),
      recommendedFormulation:
        selectedDrug?.medicalProperties?.dosageForm ||
        prescription.recommendedFormulation,
      refill: form.refill || 0,
      route: selectedDrug?.medicalProperties?.route || prescription.route,
      RxNumber: prescription.RxNumber,
      skuDescription: getSkuDescription(selectedDrug) || "",
      typeName: form.drugName,
      duration: form.duration!,
      frequency: form.frequency!,
      frequencyType: form.frequencyType,
      schedule: form.durationType,
    });

    resetForm();
  };

  const handleCancelEditRx = () => {
    setIsEditing(false);
    toggleCancelEdit();
    handleClearForm();

    //TODO: find the best solution for clearing location state in react-router-dom
    navigate(pathname, { replace: true });
  };

  const renderButtons = () => {
    if (isEditing === true) {
      return (
        <>
          <Button
            variant={ButtonVariants.outlined}
            onClick={toggleCancelEdit}
            className="issue__button"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="issue__button"
            onClick={handleEdit}
            variant={ButtonVariants.contained}
          >
            Save Rx
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          variant={ButtonVariants.outlined}
          onClick={handleClearForm}
          className="issue__button"
        >
          Clear Fields
        </Button>
        <Button
          type="submit"
          className="issue__button"
          onClick={handleSubmit(onSubmit)}
          variant={ButtonVariants.contained}
        >
          Add Item to Queue
        </Button>
      </>
    );
  };

  const renderItemInfo = () => {
    if (selectedDrug?.medlCare === true)
      return (
        <>
          <Typography variant={TypographyVariants.h6} component="p" bold>
            Medlcare coverage
          </Typography>
          <div className="issue__info-body">
            <Typography
              variant={TypographyVariants.h6}
              color={TypographyColors.lightGrey}
            >
              Items marked with this icon are covered under CDAP, and will be
              available to eligible patients free of charge once in stock.
            </Typography>
            <img
              src={MedlCareIcon}
              alt="Medl Care Icon"
              className="issue__info-icon"
            />
          </div>
        </>
      );

    if (
      selectedDrug?.bdsCode !== undefined &&
      selectedDrug?.bdsCode !== "" &&
      user?.country === Country.Barbados
    )
      return (
        <>
          <Typography variant={TypographyVariants.h6} component="p" bold>
            Barbados Drug Service Coverage
          </Typography>
          <div className="issue__info-body">
            <Typography
              variant={TypographyVariants.h6}
              color={TypographyColors.lightGrey}
            >
              Items marked with this icon are covered by the Barbados Drug
              Service
            </Typography>
            <img src={BDSIcon} alt="BDS Icon" className="issue__info-icon" />
          </div>
        </>
      );

    return null;
  };

  if (user === null) return null;
  if (patientDetails.data === undefined) return null;

  return (
    <div className="issue-prescription">
      <div className="issue__content-container">
        <Box className="box box--color-grey">
          <GoBackLink />
          <Typography variant={TypographyVariants.h4}>
            Issue Prescription
          </Typography>
          <Typography variant={TypographyVariants.h3} bold>
            {patientDetails.data.data.fullName}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"start"}
          mt={4}
        >
          <div className="issue__main-content">
            <div className="issue__main-content-link">
              <MedlLink to={""} onClick={toggle}>
                <Typography color={TypographyColors.blue}>
                  View Current Prescription
                </Typography>
              </MedlLink>
            </div>
            <form>
              <Typography
                className="issue__main-content-title"
                variant={TypographyVariants.h3}
                bold
              >
                Drug Details
              </Typography>

              <Controller
                control={control}
                name="drugName"
                rules={{ required: "The drug name is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onItemSubmit={handleDrugSelect}
                    data={drugDetailOptions}
                    error={errors.drugName?.message}
                    placeholder="Enter drug name here"
                    onClear={resetForm}
                    filter={() => true}
                  />
                )}
              />

              <Controller
                control={control}
                name={"prescribableName"}
                rules={{ required: "The prescribable name is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Prescribable Options"
                    options={prescribableOptions}
                    onChange={handlePrescribableNameSelect}
                    errorMessage={errors.prescribableName?.message}
                    error={!!errors.prescribableName}
                    itemComponent={PrescribableItem}
                  />
                )}
              />

              <div className="issue__substitutions">
                <Typography
                  variant={TypographyVariants.h6}
                  color={TypographyColors.lightGrey}
                >
                  Prices shown are as at this current time of prescribing, is
                  subject to change without notice and does not reflect
                  discounts or benefits which the Patient will be able to view
                  in their Cart once the item is added.
                </Typography>
              </div>

              <div className="issue__substitutions">{renderItemInfo()}</div>

              <Typography
                className="issue__main-content-title"
                variant={TypographyVariants.h3}
                bold
              >
                Diagnosis
              </Typography>

              <Controller
                control={control}
                name="diagnosis"
                rules={{ required: "The drug name is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onItemSubmit={handleDiagnosisSelect}
                    data={diagnosisFilter}
                    error={errors.diagnosis?.message}
                    placeholder="Enter diagnosis here"
                  />
                )}
              />

              <Typography
                className="issue__main-content-title"
                variant={TypographyVariants.h3}
                bold
              >
                Drug Dosage
              </Typography>

              {isDivisible ? (
                <DivisibleForm
                  register={register}
                  errors={errors}
                  control={control}
                />
              ) : (
                <NonDivisibleForm
                  register={register}
                  errors={errors}
                  skuDescription={getSkuDescription(selectedDrug)}
                />
              )}

              <div className="issue__fields--inline">
                <div className="issue__fields--half-width issue__fields--left">
                  <TextField
                    placeholder="Refills"
                    {...register("refill")}
                    autoComplete="off"
                    type="number"
                  />
                </div>
                <div className="issue__fields--half-width issue__fields--checkbox">
                  <Checkbox label="pro re nata" {...register("prn")} />
                </div>
              </div>

              <Typography
                className="issue__main-content-title"
                variant={TypographyVariants.h3}
                bold
              >
                {isDivisible ? "Notes" : "Dosage instructions"}
              </Typography>
              <TextArea
                {...register("notes")}
                placeholder={
                  isDivisible
                    ? "Insert any relevant notes here"
                    : "Enter specific dosage instructions, if required"
                }
                autoComplete="off"
              />
            </form>

            <div className="issue__button-group">{renderButtons()}</div>
          </div>
          {isMobile ? (
            <Modal
              isOpen={isOpen}
              toggle={toggle}
              className="prescriptions__modal"
              hasCloseButton
            >
              <PrescriptionAddedItems
                recentPrescriptions={recentPrescriptions.data?.data.map(
                  (rp) => {
                    return { ...rp, doctorId: new ObjectId(rp.doctorId._id) };
                  }
                )}
                summaryPrescriptions={summaryPrescriptions.data}
              />
            </Modal>
          ) : (
            <PrescriptionAddedItems
              recentPrescriptions={recentPrescriptions.data?.data.map((rp) => {
                return { ...rp, doctorId: new ObjectId(rp.doctorId._id) };
              })}
              summaryPrescriptions={summaryPrescriptions.data}
            />
          )}
        </Box>
      </div>

      <ConfirmationModal
        toggle={toggleCancelEdit}
        isOpen={isCancelEditOpen}
        handleConfirm={handleCancelEditRx}
        subtitle="Are you sure you want to cancel editing this prescription item?"
        primaryActionText="Yes"
        secondaryActionText="No"
        title="Edit RX"
      />
    </div>
  );
};

export default PrescriptionIssuance;
