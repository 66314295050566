import cn from "clsx";
import "./index.css";
import { Link, LinkProps } from "react-router-dom";
import { forwardRef } from "react";

export enum LinkColors {
  pink = "pink",
  blue = "blue",
  black = "black",
  red = "red",
}

const MedlLink = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { color = LinkColors.blue, children, className, to, ...props }: LinkProps,

    ref
  ) => {
    return (
      <Link
        to={to}
        className={cn(className, "link", { [`link--color-${color}`]: color })}
        {...props}
      >
        {children}
      </Link>
    );
  }
);

export default MedlLink;
