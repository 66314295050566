import { useContext } from "react";
import { FeatureContext, Features } from "../../context/features";

interface FeatureProps {
  name: Features;
  on: JSX.Element;
  off?: JSX.Element | null;
}
export const Feature = ({ name, on, off = null }: FeatureProps) => {
  const { features } = useContext(FeatureContext);

  if (process.env.REACT_APP_ENV === "local") return on;

  if (features.includes(name)) return on;

  return off;
};

export default Feature;
